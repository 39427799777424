import { useLocation } from "react-router-dom";

import PartialWrapper from "../components/containers/wrappers/PartialWrapper";
import GlobalWrapper from "../components/containers/wrappers/GlobalWrapper";
import { ThemeProvider } from "styled-components";
import { ThemeBase } from "../theme/base";
import { GlobalStyles } from "./GlobalStyles";

export default function Layout({ children }: any) {
  const location = useLocation();
  return (
    <ThemeProvider theme={ThemeBase}>
      <GlobalStyles />
      {location.pathname.includes("login") ? (
        <GlobalWrapper>{children}</GlobalWrapper>
      ) : (
        <PartialWrapper>{children}</PartialWrapper>
      )}
    </ThemeProvider>
  );
}
