import { defaultProps } from "react-toastify/dist/components/ToastContainer";
import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";
import cpfMask from "../../../helpers/mask/cpfMask";
import { IAssociate } from "../../../interfaces/associates/IAssociate";
import { IRow } from "../../../components/Table/interface/IRow";

export const AssociatesTableConfig = (defaultProps: IRow = {}) => {
  return [
    {
      name: "ID Empresa",
      cell: (row: IAssociate) => (
        <RowId {...defaultProps}> {row.companyId}</RowId>
      ),
    },
    {
      name: "Empresa",
      cell: (row: IAssociate) => (
        <RowText {...defaultProps}>{row?.company}</RowText>
      ),
    },
    {
      name: "Nome",
      cell: (row: IAssociate) => (
        <RowText {...defaultProps}>{row?.name}</RowText>
      ),
      grow: 1.8,
    },
    {
      name: "CPF",
      cell: (row: IAssociate) => (
        <RowText {...defaultProps}>{cpfMask(row.cpf)}</RowText>
      ),
      grow: 1.3,
    },

    {
      name: " ",
      cell: (row: IAssociate) => (
        <RowStatus {...defaultProps} status={row.active ? 1 : 0} />
      ),
    },
  ];
};
