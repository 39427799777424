import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IUser } from "../../../interfaces/users/IUser";

const FDetails = async ({ id }: { id?: number | string }): Promise<IUser> => {
  const URL = queryFormatter({
    route: "dashboard/app/users/" + id,
  });
  try {
    const { data }: AxiosResponse<IUser> = await axiosProvider.get(URL);
    return data;
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default FDetails;
