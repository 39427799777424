import isEqual from "lodash/isEqual";
import { useMemo } from "react";

const useVerifyObjectEqual = ({
  initialObject,
  actualObject,
}: {
  initialObject: object;
  actualObject: object;
}) => {
  const response = useMemo(() => {
    return isEqual(initialObject, actualObject);
  }, [initialObject, actualObject]);

  return response;
};

export default useVerifyObjectEqual;
