import { IDetails, IGen } from "../interfaces";

export const arrProducts = [
  { id: 1, name: "Sim" },
  { id: 2, name: "Não" },
];

export const arrGen: IGen[] = [
  {
    id: 1,
    name: "Feminino",
    abrev: "F",
  },
  {
    id: 2,
    name: "Masculino",
    abrev: "M",
  },
];

export const INITIAL_STATE = {
  cpf: "",
  name: "",
  email: "",
  companyId: "",
  tel: "",
  gender: arrGen[0],
  birthDate: "",
  products: [],
}
