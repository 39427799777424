export default function checkThirdStep(object: Record<string, any>) {
  if (typeof object.renderCep !== "boolean") return false;
  if (
    object.renderCep &&
    (object?.cep?.length < 8 ||
      !object.geolocation?.latitude ||
      !object.geolocation?.longitude)
  ) {
    return true;
  }

  return false;
}
