/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IBrand } from "./interface/IBrand";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetBrand = async ({
  search,
  page,
  limit,
  active,
}: {
  search?: string;
  limit: number;
  page: number;
  active: boolean;
}): Promise<IBrand> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/establishment/brand/get",
    querys: { limit, search, page: (page ?? 1) - 1, active },
  });

  try {
    const { data }: AxiosResponse<IBrand> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetBrand;
