import { useState } from "react";
import { getMaskType } from "../../../helpers/mask/getMaskType";
import Column from "../../containers/Column";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { IInputProps } from "../IInput";
import { StyledInput } from "../styles";
import { ColorClicker, ColorPicker } from "./styles";

const InputColor = (props: IInputProps) => {
  const { width, ...inputProps } = props;
  const [picker, setPicker] = useState(false);
  return (
    <Column
      width={width || "auto"}
      margin={props.margin || "0px"}
      position="relative"
    >
      {props.label && (
        <Row justifyContent="flex-start">
          <GenericText size="h6">{props.label}</GenericText>
        </Row>
      )}
      <StyledInput
        mask={getMaskType("color")}
        maskChar={""}
        placeholder={props.placeholder}
        {...inputProps}
        type={props.type === "color" ? "text" : props.type}
      />
      <ColorClicker
        onMouseEnter={() => setPicker(true)}
        onMouseLeave={() => setPicker(false)}
        value={(props.value as string) || "#ffffff"}
      >
        {picker && (
          <ColorPicker
            color={(props.value as string) || "#ffffff"}
            onChange={(e) => {
              if (!props.onChange) return;
              props.onChange({
                target: { value: e.hex, name: props.name || "" },
              } as never);
            }}
          />
        )}
      </ColorClicker>
    </Column>
  );
};

export default InputColor;
