export function formatDate(string?: string): string {
  if (!string) return "";
  const match = string.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (match) {
    const [, year, month, day] = match;
    return `${day}/${month}/${year}`;
  }
  string = string.replace(/\D/g, "");
  string = string.replace(/(\d{2})(\d)/, "$1/$2");
  string = string.replace(/(\d{2})(\d)/, "$1/$2");

  return string.substring(0, 10) || "";
}
