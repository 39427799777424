import { useState } from "react";
import Select from "../../../../../../components/Select";
import { YesOrNot } from "../../../../../../mocks/YesOrNot";
import Input from "../../../../../../components/Input";
import Chip from "../../../../../../components/Chip";
import cpfMask from "../../../../../../helpers/mask/cpfMask";

export default function FourthStepPush(props: {
  push: Record<string, any>;
  changePush: (e: any) => void;
}) {
  const [cpfInput, setCpfInput] = useState("");
  const handleDeleteCpf = (index: number) => {
    props.changePush((el: any) => ({
      ...el,
      users: el.users.filter((el: any, i: number) => i !== index),
    }));
  };
  return (
    <>
      <Select
        value={
          props.push?.checkFourthStep
            ? {
                label: "Sim",
                value: true,
              }
            : props.push?.checkFourthStep === false
            ? {
                label: "Não",
                value: false,
              }
            : undefined
        }
        label="Mandar para cpf específico?"
        placeholder="Mandar para cpf específico??"
        data={YesOrNot}
        getOptionLabel={(e: any) => e.label}
        getOptionValue={(e: any) => e.value}
        onChange={(e: any) => {
          props.changePush((el: any) => ({
            ...el,
            checkFourthStep: e.value,
          }));
        }}
        required
      />

      {props.push?.checkFourthStep && (
        <Input
          value={cpfInput}
          onChange={({ target }) => setCpfInput(target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (cpfInput.length !== 14) return;
              const cpf = cpfInput.replace(/\D/g, "");
              props.changePush((el: any) => ({
                ...el,
                users: el.users ? [...el?.users, cpf] : [cpf],
              }));
              setCpfInput("");
            }
          }}
          width={"100%"}
          label="CPFS*"
          placeholder="Adione cpfs para envio da notificação"
          type="text"
          margin={"5px 0px"}
          maskType="cpf"
        />
      )}

      {Array.isArray(props?.push?.users) &&
        props?.push.users.map((cpf: string, index) => (
          <Chip label={cpfMask(cpf)} onDelete={() => handleDeleteCpf(index)} />
        ))}
    </>
  );
}
