import GenericText from "../../texts/GenericText";
import { IRowStatus } from "./IRowStatus";
import { Container, Status } from "./styles";

const RowStatus = (props: IRowStatus) => {
  const getStatusLabel = () => {
    if (props.label) {
      return props.label;
    } else {
      switch (props.status) {
        case 1:
          return "Ativo";
        case 0:
          return "Inativo";
        default:
          return "Espera";
      }
    }
  };

  return (
    <Container {...props}>
      <Status status={props.status}>
        <GenericText font="Bold" size="h5" color="#fff">
          {getStatusLabel()}
        </GenericText>
      </Status>
    </Container>
  );
};

export default RowStatus;
