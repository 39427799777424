import styled from "styled-components";
import { IButtonProps } from "../Button/interfaces/IButtonProps";

export const ButtonContainer = styled.button<IButtonProps>`
  background: white;
  border-radius: 10px;
  font-size: 16px;
  height: 50px;
  border: none;
  color: #ffffff;
  width: ${(props) =>
    !props.width
      ? "100%"
      : typeof props.width === "number"
      ? props.width + "px"
      : props.width};
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  ${(props) => props.margin && `margin:${props.margin};`}
  ${(props) =>
    props.disabled &&
    `background:#D9D9D9;
   cursor:not-allowed;`}
  &:hover {
    opacity: 0.6;
  }
`;
