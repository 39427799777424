import { useEffect, useState } from "react";
import Input from "../../../components/Input";
import CustomerService from "../../../services/api/customer/CustomerService";
import changeStateByInputName from "../../../helpers/state/changeStateByInputName";
import Select from "../../../components/Select";
import { useMutation, useQuery } from "@tanstack/react-query";
import RegistrationForm from "../../../components/forms/RegistrationForm";
import AssociatesService from "../../../services/api/associates/AssociatesService";
import CnpjWithoutMask from "../../../helpers/CnpjWithoutMask";
import cleanObject from "../../../helpers/cleanObject";
import { toast } from "react-toastify";
import Row from "../../../components/containers/Row";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import GenericText from "../../../components/texts/GenericText";
import { CSCContainer } from "../../../components/forms/RegistrationForm/styles";
import { Seprator } from "../../../components/Exotics/Seprator";
import { arrGen, arrProducts, INITIAL_STATE } from "./mocks";
import { IDetails, IGen } from "./interfaces";
import { IGetProductsByCompany } from "../../../services/api/associates/interface/ICreateAssociate";
import { useParams } from "react-router-dom";
import { EHImage } from "./styles";

export default function RegisterAssociate() {
  const { id: idParams } = useParams();
  const [details, setDetails] = useState<IDetails>(INITIAL_STATE);
  const [companyName, setCompanyName] = useState("");
  const [productsIds, setProductsIds] = useState<number[]>([]);
  const [gen, setGen] = useState<IGen>({
    id: 1,
    name: "Feminino",
    abrev: "F",
  });

  const { data: detailsData, isSuccess: isSuccessDetails } = useQuery({
    enabled: !!idParams,
    queryKey: ["company-details-associates", idParams],
    queryFn: () => CustomerService.details({ id: idParams }),
  });

  const { data: companies } = useQuery({
    enabled: !idParams,
    queryKey: ["company-customers-associates", companyName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: companyName,
        type: "active",
      }),
  });

  const {
    data: productsData,
    isLoading: isLoadingProducts,
    isSuccess: isSuccessProducts,
  } = useQuery({
    queryKey: ["products-registration-associates", details?.companyId],
    queryFn: () => AssociatesService.get(details?.companyId),
    enabled: !!details?.companyId,
  });

  const {
    mutate,
    data,
    isSuccess: isSuccessRegistration,
    isPending,
  } = useMutation({
    mutationKey: ["associate-create"],
    mutationFn: AssociatesService.create,
  });

  useEffect(() => {
    if (isSuccessDetails) {
      setDetails((state) => ({
        ...state,
        companyId: detailsData.id,
        companyName: detailsData?.name,
        companyImageUrl: detailsData?.companiesImage?.image,
      }));
    }
  }, [isSuccessDetails]);

  useEffect(() => {
    if (!!productsData) {
      setDetails((state) => ({
        ...state,
        products: productsData,
      }));
    }
  }, [productsData]);

  useEffect(() => {
    if (isSuccessRegistration) {
      toast("Associado criado com sucesso 👌", { type: "success" });
      clearForm();
    }
  }, [isSuccessRegistration]);

  const handleSelectChange = (productId: number, selectedOption: any) => {
    setProductsIds((prevSelectedProducts) => {
      if (selectedOption.id === 1) {
        if (prevSelectedProducts.includes(productId)) {
          return prevSelectedProducts;
        }
        return [...prevSelectedProducts, productId];
      } else {
        return prevSelectedProducts.filter((id) => id !== productId);
      }
    });
  };

  const create = () => {
    const newObject = {
      cpf: CnpjWithoutMask(details?.cpf || ""),
      name: details?.name,
      email: details?.email,
      companyId: details?.companyId,
      phone: details?.tel,
      gender: gen?.abrev,
      birthDate: details?.birthDate,
      products: [...productsIds],
    };
    mutate(cleanObject(newObject));
  };

  let timeoutRef: any;
  const handleCompanyName = (value: string) => {
    clearTimeout(timeoutRef);

    timeoutRef = setTimeout(() => {
      setCompanyName(value);
    }, 2000);
  };

  const clearForm = () => {
    setDetails((state) => ({
      ...INITIAL_STATE,
      companyName: state?.companyName,
      companyId: state?.companyId,
      products: state?.products,
    }));
    setGen(arrGen[0]);
    setProductsIds([]);
  };

  return isLoadingProducts ? (
    <LoaderGlobal />
  ) : (
    <>
      <RegistrationForm
        title="Insira os dados principais do cliente"
        buttonProps={{
          onClick: () => {
            create();
          },
          disabled: !details.name || !details?.companyId || !details.cpf,
          loading: isPending,
          width: "300px",
        }}
        renderHeaderComponent={() => (
          <Row justifyContent="space-between">
            <Select
              data={companies?.data || []}
              getOptionLabel={(option: any) => option?.name}
              getOptionValue={(option: any) => option?.id}
              onInputChange={(e) => {
                handleCompanyName(e);
              }}
              placeholder="Empresa"
              onChange={(e: any) => {
                setDetails((state) => ({
                  ...state,
                  companyId: e?.id,
                  companyName: e?.name,
                }));
              }}
              value={
                {
                  name: details?.companyName ?? "Selecione uma empresa",
                  id: details?.companyId,
                } || ""
              }
              label="Empresa"
              isDisabled={!!idParams}
              width={"350px"}
            />
            {!!idParams && (
              <EHImage>
                <img
                  alt="entity-image"
                  style={{ width: "75px", height: "75px" }}
                  src={`${details?.companyImageUrl}`}
                />
              </EHImage>
            )}
          </Row>
        )}
        renderInsideComponents={() => (
          <>
            <CSCContainer>
              <Input
                onChange={(e) => changeStateByInputName(e, setDetails)}
                name="name"
                value={details?.name || ""}
                width={"100%"}
                label="Nome"
                type="text"
                required
                placeholder="Nome"
                disabled={!isSuccessProducts}
                margin={"0px 0px 5px 0px"}
              />
              <Input
                onChange={(e) => changeStateByInputName(e, setDetails)}
                name="cpf"
                value={details?.cpf || ""}
                width={"100%"}
                label="CPF"
                maskType="cpf"
                required
                placeholder="CPF"
                disabled={!isSuccessProducts}
              />
              <Input
                onChange={(e) => changeStateByInputName(e, setDetails)}
                name="tel"
                value={details?.tel || ""}
                width={"100%"}
                label="Telefone"
                maskType="tel"
                placeholder="Telefone"
                disabled={!isSuccessProducts}
                margin={"0px 0px 5px 0px"}
              />
              <Input
                onChange={(e) => changeStateByInputName(e, setDetails)}
                name="birthDate"
                value={details?.birthDate || ""}
                width={"100%"}
                label="Data de nascimento"
                type="date"
                placeholder="Data de nascimento"
                disabled={!isSuccessProducts}
              />
              <Input
                onChange={(e) => changeStateByInputName(e, setDetails)}
                name="email"
                value={details?.email || ""}
                width={"100%"}
                label="E-mail"
                type="text"
                placeholder="e-mail"
                disabled={!isSuccessProducts}
                margin={"0px 0px 5px 0px"}
              />
              <Select
                data={arrGen || []}
                getOptionLabel={(item: any) => item?.name}
                getOptionValue={(item: any) => item?.id}
                onChange={(e: any) => {
                  setGen(e);
                }}
                placeholder="Gênero"
                label="Gênero"
                value={gen}
                isDisabled={!isSuccessProducts}
              />
            </CSCContainer>
            {!!productsData && (
              <>
                <Seprator />
                <GenericText
                  margin={"8px 0px 8px 20px"}
                  alignSelf="flex-start"
                  size={"h4"}
                >
                  Produtos
                </GenericText>
                <CSCContainer>
                  {details?.products?.map(
                    (productData: IGetProductsByCompany, index) => {
                      return (
                        <Select
                          key={`products-customer-registration-${index}`}
                          data={arrProducts || []}
                          getOptionLabel={(option: any) => option?.name}
                          getOptionValue={(option: any) => option?.id}
                          onChange={(selectedOption) =>
                            handleSelectChange(productData?.id, selectedOption)
                          }
                          label={productData?.appName}
                          placeholder={`Selecione ${productData?.appName}`}
                          value={
                            productsIds.includes(productData.id)
                              ? { id: 1, name: "Sim" }
                              : { id: 2, name: "Não" }
                          }
                        />
                      );
                    }
                  )}
                </CSCContainer>
              </>
            )}
          </>
        )}
      />
    </>
  );
}
