import { NavLink as NativeLink } from "react-router-dom";
import styled from "styled-components";
import Row from "../../containers/Row";

interface ISidebarItemWrapper {
  isMyItem: boolean;
}

interface IToggleButton {
  isOpen: boolean;
}

export const SidebarItemWrapper = styled.div<ISidebarItemWrapper>`
  width: 100%;
  background-color: transparent;
  color: ${(props) => (props.isMyItem ? "#1b1b1b" : "#d7d7d7")};
  margin: 5px 0px;
  ${(props) => !props.isMyItem && "background: #ffffff;"}
  &:hover {
    border-radius: 8px;
    cursor: pointer;
    ${(props) => props.isMyItem && "background: #ffffff;"}
  }
`;

export const NavLink = styled(NativeLink)`
  text-decoration: none;
`;

export const SidebarTitle = styled(Row)`
  padding: 0.75em 1em;
  transition: background-color 0.15s;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  &.active {
    color: #ffffff;
    background: #1b1b1b;
  }
`;

export const SidebarContent = styled.div<IToggleButton>`
  height: ${(props) => (props.isOpen ? "auto" : "0")};
  overflow: hidden;
  margin: ${(props) => (props.isOpen ? "4px 0" : "0")};
  background: #ffffff;
`;

export const PlainNavLink = styled(NavLink)<{ active: boolean }>`
  display: flex;
  align-items: center;
  color: #a1a1a1;
  text-decoration: none;
  padding-left: 20px;
  margin-bottom: 7px;
  padding: 6px 8px;
  ${(props) => props.active && "background-color: #f5f5f5;"}
  &:hover {
    background-color: #f5f5f5;
  }
`;
