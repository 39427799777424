/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IAccess } from "./interface/IAccess";

const FGetAccess = async (id: string): Promise<IAccess> => {
  const URL = queryFormatter({
    route: `dashboard/stations/access/${id}`,
  });
  try {
    const { data }: AxiosResponse<IAccess> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FGetAccess;
