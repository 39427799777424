import GenericText from "../../texts/GenericText";
import { IRow } from "../interface/IRow";
import { Container, IdTag } from "./styles";

const RowId = (props: IRow) => {
  return (
    <Container {...props}>
      <IdTag>
        <GenericText font="Bold" size="h5" color="#fff">
          {props.children}
        </GenericText>
      </IdTag>
    </Container>
  );
};

export default RowId;
