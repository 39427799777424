import styled from "styled-components";
import Column from "../../containers/Column";

export const RegistrationContainer = styled(Column)`
  padding: 10px;
  box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.2);
  min-width: 170px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 80%;
  position: relative;
  flex: 1;
  margin-top: 50px;
  display: flex;
  height: auto;
`;

export const CSCContainer = styled.div`
  column-count: 3; /* número de colunas desejado */
  column-fill: balance;
  column-gap: 30px; /* espaço entre as colunas (2% da largura do container) */
  width: 100%;
  padding: 20px 20px;

  & > * {
    break-inside: avoid; /* evita que os componentes sejam divididos em colunas */
  }

  @media (max-width: 900px) {
    column-count: 2;
  }

  @media (max-width: 600px) {
    column-count: 1;
  }
`;
