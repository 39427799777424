import FDetails from "./FDetails";
import FReset from "./FReset";
import FSearch from "./FSearch";
import FSummary from "./FSummary";

class Service {
  summary = FSummary;
  search = FSearch;
  details = FDetails;
  reset = FReset;
}

export default new Service();
