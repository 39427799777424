/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IProfile } from "../../../interfaces/authentication/IProfile";

const FProfile = async (): Promise<IProfile> => {
  const URL = queryFormatter({
    route: "dashboard/profile",
  });

  try {
    const { data }: AxiosResponse<IProfile> = await axiosProvider.get(URL);
    return data;
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default FProfile;
