import styled from "styled-components";
import Row from "../../Row";

export const GWContainer = styled(Row)`
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  /* background-color: #f4f4f4; */
`;
