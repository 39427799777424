/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const getMvnoIntegration = async (id: number): Promise<{ token: string }> => {
  const URL = queryFormatter({
    route: "mvno/dashboard/company/" + id,
    querys: {},
  });

  try {
    const { data }: AxiosResponse<{ token: string }> = await axiosProvider.get(
      URL
    );
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default getMvnoIntegration;
