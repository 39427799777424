/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import ICities from "./interfaces/ICities";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetCities = async (id: string): Promise<ICities[]> => {
  const URL = queryFormatter({
    route: `locations/cities/${id}`,
    querys: {  },
  });

  try {
    const { data }: AxiosResponse<ICities[]> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetCities;
