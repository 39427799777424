/* eslint-disable react-hooks/exhaustive-deps */

import { useMutation } from "@tanstack/react-query";
import { ICustomer } from "../../../../interfaces/customer/ICustomer";
import CustomerService from "../../../../services/api/customer/CustomerService";
import { useEffect, useState } from "react";
import useVerifyObjectEqual from "../../../../hooks/useVerifyObjectEqual";
import convertEmptyStringsToNullOrDelete from "../../../../helpers/object/convertEmptyStringsToNull ";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";
import { toast } from "react-toastify";
import Input from "../../../../components/Input";
import changeStateByInputName from "../../../../helpers/state/changeStateByInputName";

const MkInformation = ({ basic }: { basic: ICustomer }) => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["custumer-update-marketing"],
    mutationFn: CustomerService.update,
  });

  const [details, setDetails] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState({});

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: details,
  });

  function init() {
    const object = {
      backgroundColor: basic.companiesColor.backgroundColor || null,
      site: basic.site || null,
      instagram: basic.instagram || null,
      android: basic.android,
      ios: basic.ios,
      supportLink: basic.supportLink || null,
    };
    setDetails(object);
    setInitialObject(object);
  }

  const update = () => {
    const updateMarketingAndColor = convertEmptyStringsToNullOrDelete(
      details,
      true
    );
    setInitialObject(details);
    mutate({ id: basic.id, update: updateMarketingAndColor });
  };

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Cor & Marketing"
      buttonProps={{
        disabled: disabledButton,
        onClick: update,
        loading: isPending,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            width={"100%"}
            label="Link site"
            value={details.site}
            name="site"
            placeholder="Insira o link do cliente"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            type="text"
            margin={"5px 0px"}
          />

          <Input
            width={"100%"}
            name={"instagram"}
            value={details.instagram}
            label="Instagram"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            placeholder="@stations"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            width={"100%"}
            name={"android"}
            value={details.android}
            label="Google Play"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            placeholder="Link do app android"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            width={"100%"}
            name={"ios"}
            value={details.ios}
            label="App Store"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            placeholder="Link do app ios"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="supportLink"
            value={details.supportLink || ""}
            width={"100%"}
            label="Link para suporte"
            placeholder="Canal de suporte do cliente"
            type="text"
            margin={"5px 0px"}
          />
          <Input
            name="backgroundColor"
            width={"100%"}
            value={details.backgroundColor}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            label="Cor principal"
            placeholder="#ffffff"
            type="color"
            margin={"5px 0px"}
          />
        </>
      )}
    />
  );
};

export default MkInformation;
