import { SVGProps } from "react";
const UserProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 229.99 229.99"
    width={75}
    height={75}
  >
    <defs>
      <style>{".cls-1{fill:#1a1a1a}"}</style>
    </defs>
    <g id="Calque_2" data-name="Calque 2">
      <g id="Calque_1-2" data-name="Calque 1">
        <path
          d="M115 15a100.11 100.11 0 0 1 100 100 98.89 98.89 0 0 1-11.3 46.2 101.14 101.14 0 0 1-30.14 34.86 100 100 0 0 1-117.12 0 101.06 101.06 0 0 1-30.15-34.86A98.89 98.89 0 0 1 15 115 100.11 100.11 0 0 1 115 15m0-15a115 115 0 1 0 115 115A115 115 0 0 0 115 0Z"
          className="cls-1"
        />
        <path
          d="M122.27 145.08a45.14 45.14 0 0 1 45.08 45.09v10a100.12 100.12 0 0 1-104.71 0v-10a45.14 45.14 0 0 1 45.08-45.09h14.55m0-15h-14.55a60.08 60.08 0 0 0-60.08 60.09v18a115 115 0 0 0 134.71 0v-18a60.08 60.08 0 0 0-60.08-60.09Z"
          className="cls-1"
        />
        <path
          d="M115 73.31a18.82 18.82 0 1 1-18.82 18.81A18.83 18.83 0 0 1 115 73.31m0-15a33.82 33.82 0 1 0 33.81 33.81A33.82 33.82 0 0 0 115 58.31Z"
          className="cls-1"
        />
      </g>
    </g>
  </svg>
);
export default UserProfile;
