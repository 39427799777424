interface ICurrency {
  target: {
    value: any;
  };

  setValue: (value: any) => void;
}

export function currencyMask({ target: { value }, setValue }: ICurrency) {
  const removeNonNumericCharacters = String(value).replace(/\W|\D/gm, "");
  let refactoringValue = removeNonNumericCharacters.replace(/^0+/, "");

  while (refactoringValue.length < 3) refactoringValue = "0" + refactoringValue;

  const finishValue = refactoringValue.split("");
  finishValue.splice(finishValue.length - 2, 0, ",");

  for (let i = 6; i < finishValue.length; i += 4) {
    finishValue.splice(finishValue.length - i, 0, ".");
  }
  let formattedResult = finishValue.join("");
  if (formattedResult.length === 0 || formattedResult === "0,00") {
    setValue("");
  } else {
    setValue("R$ " + formattedResult);
  }
}
