/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import CinemaService from "../../../services/api/cinema/CinemaService";
import EntityHeader from "../../../components/headers/EntityHeader";
import { CSCContainer } from "../../../components/containers/ContentSpacingContainer/styles";
import BasicInformation from "./DetailsBoxes/BasicInformation";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal";
import MkInformation from "./DetailsBoxes/MkInformation";
import ImagesInformation from "./DetailsBoxes/ImagesInformation";
import { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/Modal";
import EditStatus from "../../../components/EditStatus/EditStatus";
import { toast } from "react-toastify";
import VoucherInformationExcel from "./DetailsBoxes/VoucherInformationExcel";
import VoucherInformationManual from "./DetailsBoxes/VoucherInformationManual";
import ProductInformation from "./DetailsBoxes/ProductInformation";
import ProductRule from "./DetailsBoxes/ProductRule";
import Box from "../../../components/containers/Box";
import Row from "../../../components/containers/Row";
import Card from "../../../components/Card";
import { Seprator } from "../../../components/Exotics/Seprator";
const CinemaDetails = () => {
  const [open, setOpen] = useState(false);
  const [selectStatus, setSelectStatus] = useState<{
    id?: number;
    name?: string;
  }>({});
  const [reason, setReason] = useState("");

  let { id } = useParams();
  const navigate = useNavigate();
  if (!id) {
    navigate("/");
    return <></>;
  }

  const { data, isLoading } = useQuery({
    queryKey: ["cinema-details", id],
    queryFn: () => CinemaService.getBrandDetails(Number(id)),
  });
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["update-information-cinema"],
    mutationFn: CinemaService.updateCinema,
    onSuccess: () => handleCloseModal(),
  });
  const { mutate: updateImage } = useMutation({
    mutationKey: ["update-cinema-images"],
    mutationFn: CinemaService.updateImages,
  });

  const { data: summary } = useQuery({
    queryKey: ["get-summary-vouchers", id],
    queryFn: () => CinemaService.getSummaryProducts(Number(id)),
  });

  const handleCloseModal = () => {
    setReason("");
    setOpen(!open);
  };

  const handleConfirm = useCallback(() => {
    if (data && selectStatus.id) {
      const body = {
        establishmentInfo: {
          id: data.id,
          name: data.name,
          active: selectStatus.id === 1,
        },
        comment: reason,
      };
      mutate(body as any);
      data.active = selectStatus.id === 1 ? true : false;
    }
  }, [data, reason, selectStatus.id]);

  const disableButton = useMemo(() => {
    if (!reason.length || reason.length <= 40) return true;
    const verifyStatus = data?.active
      ? { id: 1, name: "Ativar" }
      : { id: 2, name: "Inativar" };

    return verifyStatus.id === selectStatus.id;
  }, [reason.length, selectStatus?.id, data?.active]);

  const init = () => {
    if (data?.active) {
      setSelectStatus({
        id: 1,
        name: "Ativar",
      });
    } else {
      setSelectStatus({
        id: 2,
        name: "Inativar",
      });
    }
  };

  useEffect(() => {
    data && init();
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Cinema editado com sucesso");
    }
  }, [isSuccess]);

  return (
    <>
      <EntityHeader
        title={"Detalhes do Cinema "}
        name={data?.name || ""}
        brand={data?.images?.logo}
        names={[]}
        id={data?.id.toString() || ""}
        statusButtonProps={{
          onClick: () => setOpen(!open),
        }}
        status={data?.active ? "active" : "inactive"}
        sendChangeImage={(blob) => {
          const form = new FormData();
          form.append("file", blob);
          updateImage({
            establishmentId: data?.id || 0,
            imageType: "logo",
            form: form,
          });
        }}
      />
      <Box width={"99%"} margin={"10px 0px"}>
        <Row justifyContent="space-around" wrap="wrap" margin={"10px 0px"}>
          <Card
            title={summary?.active || 0}
            subTitle="Produtos Ativos"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/cinema/detalhes/ingressos/${data?.id}`),
            }}
            margin={"0px 0px 10px 0px"}
          />
          <Card
            title={summary?.inactive || 0}
            subTitle="Produtos Inativos"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/cinema/detalhes/ingressos/${data?.id}`),
            }}
            margin={"0px 0px 10px 0px"}
          />

          <Card
            title={summary?.soldOut || 0}
            subTitle="Produtos esgotado"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/cinema/detalhes/ingressos/${data?.id}`),
            }}
            margin={"0px 0px 10px 0px"}
          />
        </Row>
      </Box>
      <Seprator margin={"10px 0px"} />
      <CSCContainer>
        {data?.id && <BasicInformation basic={data} />}
        {data?.id && <ImagesInformation basic={data} />}
        {data?.id && <MkInformation basic={data} />}
        {data?.id && <VoucherInformationManual basic={data} />}
        {data?.id && <VoucherInformationExcel basic={data} />}
        {data?.id && <ProductRule basic={data} />}
        {data?.id && <ProductInformation basic={data} />}
      </CSCContainer>
      {isLoading && <LoaderGlobal />}
      <Modal open={open} onClose={() => handleCloseModal()}>
        <EditStatus
          title="Modificar status do cinema"
          subTitle="Motivo"
          width="500px"
          confirmButtonProps={{
            disabled: disableButton,
            loading: isPending,
            onClick: handleConfirm,
          }}
          selectProps={{
            value: selectStatus,
            placeholder: "Selecione um status",
            data: [
              {
                id: 1,
                name: "Ativar",
              },
              {
                id: 2,
                name: "Inativar",
              },
            ],
            getOptionLabel: (option: any) => option.name,
            getOptionValue: (option: any) => option.id,
            onChange: (e: any) => setSelectStatus(e),
          }}
          textAreaProps={{
            value: reason,
            onChange: ({ target: { value } }) => setReason(value),
          }}
        />
      </Modal>
    </>
  );
};

export default CinemaDetails;
