import { IButtonProps } from "../buttons/Button/interfaces/IButtonProps";
import Modal from "../Modal";
import GenericText from "../texts/GenericText";
import { Container, ContainerTextArea } from "./styles";
import { IGenericTextProps } from "../texts/GenericText/interfaces/IGenericTextProps";
import Input from "../Input";
import Row from "../containers/Row";
import Button from "../buttons/Button";
import { IContainerProps } from "../containers/IContainerProps";
import { IInputProps } from "../Input/IInput";

interface IPopUp {
  title: string;
  subTitle: string;
  open: boolean;
  onClose: () => void;
  titleProps?: IGenericTextProps;
  buttonProps?: IButtonProps;
  textAreaProps?: IInputProps;
  subTitleProps?: IGenericTextProps;
  popUpProps?: IContainerProps;
}

const PopUp = ({
  open,
  onClose,
  title,
  subTitle,
  buttonProps = {},
  textAreaProps = {},
  titleProps = {},
  subTitleProps = {},
  popUpProps = {},
}: IPopUp) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container width="450px" {...popUpProps}>
        <GenericText size="h3" margin="0px 0px 12px" {...titleProps}>
          {title}
        </GenericText>
        <ContainerTextArea>
          <GenericText
            size="h3"
            margin="0px 0px 5px"
            alignSelf="flex-start"
            {...subTitleProps}
          >
            {subTitle}
          </GenericText>
          <Input type="textArea" rows={6} {...textAreaProps} />
        </ContainerTextArea>
        <Row justifyContent="space-around">
          <Button
            text="Confirmar"
            width="90%"
            height="40px"
            disabled
            {...buttonProps}
          />
        </Row>
      </Container>
    </Modal>
  );
};

export default PopUp;
