/* eslint-disable no-throw-literal */
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const FReset = async (id: string): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/app/users/email/` + id,
  });
  try {
    await axiosProvider.post(URL);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FReset;
