import moment from "moment";
import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";
import { GoX } from "react-icons/go";
import { IRow } from "../../../components/Table/interface/IRow";

export const voucherDetails = (defaultProps: IRow = {}) => {
  const { onClick = () => {} } = defaultProps;
  return [
    {
      name: "ID",
      cell: (row: any) => <RowId {...defaultProps}>{row.id}</RowId>,
    },
    {
      name: "Produto",
      cell: (row: any) => (
        <RowText {...defaultProps}>{row.productInfo.name}</RowText>
      ),
    },
    {
      name: "Codigo ingresso",
      cell: (row: any) => (
        <RowText {...defaultProps}>{row.voucherCode}</RowText>
      ),
    },
    {
      name: "ID lote",
      cell: (row: any) => <RowText {...defaultProps}>{row.batchId}</RowText>,
    },
    {
      name: "Data de expiraçao",
      cell: (row: any) => (
        <RowText>{moment(row.expireAt).format("DD/MM/YYYY")}</RowText>
      ),
    },
    {
      name: " ",
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <RowStatus
            status={
              row.status === "disponível"
                ? 1
                : row.status === "indisponível"
                ? 2
                : 3
            }
            label={row.status}
          />
          {row.status === "disponível" && (
            <div
              {...defaultProps}
              onClick={() => onClick(row)}
              style={{ cursor: "pointer" }}
            >
              {<GoX size={30} />}
            </div>
          )}
        </div>
      ),
    },
  ];
};
