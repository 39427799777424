/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IGetCompany, IGetCompanyParams } from "./interface/IGetCompany";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetCompany = async ({
  limit,
  page,
  state,
  name,
  type,
}: IGetCompanyParams): Promise<IGetCompany> => {
  const URL = queryFormatter({
    route: `dashboard/company`,
    querys: { limit, page: (page ?? 1) - 1, state, name, type },
  });
  try {
    const { data }: AxiosResponse<IGetCompany> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetCompany;
