import React, { ReactNode } from "react";
import ReactModal from "react-modal";
import { ModalContent, ModalOverlay } from "./styles";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement("#root");

interface IModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  shouldCloseOnEsc?: boolean;
  shouldFocusAfterRender?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldReturnFocusAfterClose?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  open,
  onClose,
  children,
  shouldCloseOnEsc = true,
  shouldFocusAfterRender = true,
  shouldCloseOnOverlayClick = true,
  shouldReturnFocusAfterClose = true,
}) => {
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldFocusAfterRender={shouldFocusAfterRender}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      contentElement={(props, children) => (
        <ModalContent {...props}>{children}</ModalContent>
      )}
      overlayElement={(props, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
