export default function cpfMask(
  cpf: string = "",
  { mask }: { mask?: boolean } = { mask: true }
) {
  cpf = cpf.replace(/\D/g, "");
  if (cpf.length !== 11) {
    return cpf;
  }
  if (mask) {
    cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return cpf;
}
