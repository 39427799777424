/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IPlan } from "./interface/IPlans";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetPlanId = async (id: number): Promise<IPlan> => {
  const URL = queryFormatter({ route: `plan/dashboard/admin/plan/${id}` });
  try {
    const { data }: AxiosResponse<IPlan> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetPlanId;
