/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const FCreateAccess = async (body: {
  email: string;
  password: string;
  companyId: string | number;
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/stations/access`,
  });
  try {
    const { data }: AxiosResponse<void> = await axiosProvider.post(URL, {
      ...body,
      modules: [],
    });
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FCreateAccess;
