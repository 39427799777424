import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";
import cpfMask from "../../../helpers/mask/cpfMask";
import { IAssociate } from "../../../interfaces/associates/IAssociate";
import { IRow } from "../../../components/Table/interface/IRow";
import { IUser } from "../../../interfaces/users/IUser";
import moment from "moment";

export const UserTableConfig = (defaultProps: IRow = {}) => {
  return [
    {
      name: "Empresa",
      cell: (row: IUser) => <RowId {...defaultProps}> {row.companyId}</RowId>,
    },

    {
      name: "Nome",
      cell: (row: IUser) => <RowText {...defaultProps}>{row?.name}</RowText>,
      grow: 1.8,
    },
    {
      name: "CPF",
      cell: (row: IUser) => (
        <RowText {...defaultProps}>{cpfMask(row.cpf)}</RowText>
      ),
      grow: 1.3,
    },
    {
      name: "EMAIL",
      cell: (row: IUser) => <RowText {...defaultProps}>{row.email}</RowText>,
      grow: 1.3,
    },
    {
      name: "Criado em",
      cell: (row: IUser) => (
        <RowText {...defaultProps}>
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </RowText>
      ),
    },
    {
      name: " ",
      cell: (row: IAssociate) => <RowStatus {...defaultProps} status={1} />,
    },
  ];
};
