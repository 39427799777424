/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";

const UpdateStatus = async ({
  id,
  body,
}: {
  id: number;
  body: {
    action: string;
    comments: string;
  };
}): Promise<void> => {
  const URL = queryFormatter({ route: `dashboard/company/active/${id}` });

  try {
    await axiosProvider.put(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateStatus;
