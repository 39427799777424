import LogoIcon from "../../assets/svg/LogoIcon";
import SidebarItem from "./SlideBarItem";
import {
  LogoLink,
  NavbarWrapper,
  NavList,
  SidebarCustom,
  Wrap,
} from "./styles";
import { mock } from "./mock";

const Navbar = () => {
  return (
    <NavbarWrapper>
      <Wrap>
        <LogoLink to="/">
          <LogoIcon width={150} height={50} />
        </LogoLink>
        <NavList>
          <SidebarCustom>
            {mock &&
              mock.modules.map((item, i) => (
                <SidebarItem
                  item={item}
                  key={`items-side-bar-${item.title}-${i}`}
                />
              ))}
          </SidebarCustom>
        </NavList>
      </Wrap>
    </NavbarWrapper>
  );
};

export default Navbar;
