import Column from "../../containers/Column";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { IInputSearch } from "./IInputSearch";
import { StyledInput } from "./styles";

const InputSearch = (props: IInputSearch) => {
  const { width, ...inputProps } = props;
  return (
    <Column width={width || "auto"} margin={props.margin || "0px"}>
      {props.label && (
        <Row justifyContent="flex-start">
          <GenericText size="h5" font="Bold">
            {props.label}
          </GenericText>
        </Row>
      )}
      <StyledInput placeholder={props.placeholder} {...inputProps} />
    </Column>
  );
};

export default InputSearch;
