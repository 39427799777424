export function cnpjMask(
  cnpj = "",
  { mask }: { mask?: boolean } = { mask: true }
) {
  if (!cnpj) {
    return "";
  }
  cnpj = cnpj.replace(/[^\d]+/g, "");
  if (!mask) return;
  const cnpjFormated = cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
  return cnpjFormated;
}
