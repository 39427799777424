import styled from "styled-components";

export const ChipContainer = styled.div<{
  bgColor?: string;
  textColor: string;
}>`
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.primary};
  color: white;
  margin: 4px;
  .delete-icon {
    margin-left: 8px;
    font-size: 15px;
    cursor: pointer;
  }
`;
