import Column from "../../containers/Column";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { IInputProps } from "../IInput";
import { StyledInput } from "./styles";

const InputPercentage = (props: IInputProps) => {
  const { width, ...inputProps} = props;
  const { onChange } = inputProps;

  const handleInputChange = (values: any) => {
    if (onChange) {
      const { floatValue } = values;
      if (floatValue <= 100) {
        onChange(floatValue);
      }
    }
  };

  return (
    <Column width={width || "auto"} margin={props.margin || "0px"}>
      {props.label && (
        <Row justifyContent="flex-start" margin="0px 0px 0.4rem 0px">
          <GenericText size="h6">{props.label}</GenericText>
        </Row>
      )}
      <StyledInput
        suffix="%"
        allowNegative={false}
        decimalScale={2}
        maxLength={4}
        onValueChange={(e) => handleInputChange(e)}
        {...inputProps}
      />
    </Column>
  );
};

export default InputPercentage;
