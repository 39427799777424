/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import PlansService from "../../../../services/api/Plans/PlansService";
import EntityHeader from "../../../../components/headers/EntityHeader";
import { CSCContainer } from "../../../../components/containers/ContentSpacingContainer/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoaderGlobal from "../../../../components/Exotics/loaders/LoaderGlobal";
import Modal from "../../../../components/Modal";
import EditStatus from "../../../../components/EditStatus/EditStatus";
import BasicInformation from "./DetailsBoxes/BasicInformation";
import { toast } from "react-toastify";

const PlansDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  if (!id) {
    navigate("/");
    return <></>;
  }

  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [selectStatus, setSelectStatus] = useState<{
    id?: number;
    name?: string;
  }>({});

  const { data, isLoading } = useQuery({
    queryKey: ["get-plan-id", id],
    queryFn: () => PlansService.getPlanId(Number(id)),
  });

  const { mutate, isSuccess } = useMutation({
    mutationKey: ["updatePlan-status"],
    mutationFn: PlansService.updatePlan,
  });

  const init = () => {
    if (data?.status) {
      setSelectStatus({
        id: 1,
        name: "Ativar",
      });
    } else {
      setSelectStatus({
        id: 2,
        name: "Inativar",
      });
    }
  };

  const disableButton = useMemo(() => {
    if (!reason.length || reason.length <= 40) return true;
    const verifyStatus = data?.status
      ? { id: 1, name: "Ativar" }
      : { id: 2, name: "Inativar" };

    return verifyStatus.id === selectStatus.id;
  }, [reason.length, selectStatus?.id, data?.status]);

  const handleCloseModal = () => {
    setReason("");
    setOpen(!open);
  };

  const handleConfirm = useCallback(() => {
    if (data && selectStatus.id) {
      const body = {
        ...data,
        status: selectStatus.id === 1,
      };
      data.status = selectStatus.id === 1;
      mutate(body as any);
      setOpen(!open);
    }
  }, [data, selectStatus.id]);

  useEffect(() => {
    data && init();
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Status do plano atualizado com sucesso");
    }
  }, [isSuccess]);

  return (
    <>
      <EntityHeader
        title="Detalhes do plano"
        name={data?.title || ""}
        companyImage
        status={data?.status ? "active" : "inactive"}
        id={data?.id || ""}
        names={[]}
        statusButtonProps={{
          onClick: () => setOpen(!open),
        }}
      />
      <CSCContainer>
        {data?.id && <BasicInformation basic={data} />}
      </CSCContainer>
      {isLoading && <LoaderGlobal />}
      <Modal open={open} onClose={() => handleCloseModal()}>
        <EditStatus
          title="Modificar status do plano"
          subTitle="Motivo"
          width="500px"
          confirmButtonProps={{
            disabled: disableButton,
            onClick: handleConfirm,
          }}
          selectProps={{
            value: selectStatus,
            placeholder: "Selecione um status",
            data: [
              {
                id: 1,
                name: "Ativar",
              },
              {
                id: 2,
                name: "Inativar",
              },
            ],
            getOptionLabel: (option: any) => option.name,
            getOptionValue: (option: any) => option.id,
            onChange: (e: any) => setSelectStatus(e),
          }}
          textAreaProps={{
            value: reason,
            onChange: ({ target: { value } }) => setReason(value),
          }}
        />
      </Modal>
    </>
  );
};

export default PlansDetails;
