import styled from "styled-components";
import Column from "../Column";
import Row from "../Row";

export const CSCContainer = styled.div`
  column-count: 4; /* número de colunas desejado */
  column-fill: balance;
  column-gap: 30px; /* espaço entre as colunas (2% da largura do container) */
  width: 100%;
  padding: 20px 20px;

  & > * {
    break-inside: avoid; /* evita que os componentes sejam divididos em colunas */
  }
  @media (max-width: 1500px) {
    column-count: 3;
  }

  @media (max-width: 900px) {
    column-count: 2;
  }

  @media (max-width: 600px) {
    column-count: 1;
  }
`;
