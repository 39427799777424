/* eslint-disable no-throw-literal */

import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

export interface ISummaryVoucher {
  available: number;
  user: number;
  withdraw: number;
  total: number;
}

const GetSummaryVoucher = async (
  establishmentId: number
): Promise<ISummaryVoucher> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/voucher/count",
    querys: { establishmentId },
  });
  try {
    const { data }: AxiosResponse<ISummaryVoucher> = await axiosProvider.get(
      URL
    );
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};
export default GetSummaryVoucher;
