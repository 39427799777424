/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useMemo } from "react";
import AuthContext from "./IAuthContext";
import { IAuthContext } from "./IContextAuth";
import useLoginController from "../controllers/authentication/login/useLoginController";
import useTokenController from "../controllers/authentication/token/useTokenController";

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { changeToken, token, logout, profile } = useTokenController();
  const login = useLoginController(changeToken);
  const response: IAuthContext = useMemo(
    () => ({
      login,
      token,
      logout,
      profile,
    }),
    [token, login.loading , profile]
  );

  return (
    <AuthContext.Provider value={response}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
