/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const Flogin = async (body: {
  login: string;
  password: string;
}): Promise<{ token: string }> => {
  const URL = queryFormatter({
    route: "dashboard/login",
  });

  try {
    const { data }: AxiosResponse<{ token: string }> = await axiosProvider.post(
      URL,
      body
    );
    return data;
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default Flogin;
