/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IResponsePlan } from "./interface/IPlans";

const GetPlans = async ({
  limit,
  page,
  companyId,
  limitDate,
  search,
  status,
}: {
  companyId?: number;
  status?: boolean;
  limitDate?: string;
  search?: string;
  page: number;
  limit: number;
}): Promise<IResponsePlan> => {
  const URL = queryFormatter({
    route: "plan/dashboard/admin/plan",
    querys: {
      companyId,
      page: (page ?? 1) - 1,
      limit,
      limitDate,
      search,
      status,
    },
  });

  try {
    const { data }: AxiosResponse<IResponsePlan> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};
export default GetPlans;
