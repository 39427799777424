import styled from "styled-components";
import { IBoxProps } from "./IBoxProps";
import Row from "../Row";

export const Box = styled(Row)<IBoxProps>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  background: #ffffff;
  box-shadow: ${({ shadow }) =>
    shadow
      ? "0 6px 6px rgba(0, 0, 0, 0.1), 0 3px 6px 6px rgba(0, 0, 0, 0.08)"
      : "none"};
  border-radius: 10px;
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: ${({ content }) => content || "center"};
  padding: 40px 0px;
`;
