/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IProducts } from "./interface/IProducts";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetProductsCinema = async (
  establishmentId: number
): Promise<IProducts> => {
  const URL = queryFormatter({
    route: `cinema/dashboard/shop/get`,
    querys: { establishmentId },
  });

  try {
    const { data }: AxiosResponse<IProducts> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetProductsCinema;
