export default function cleanObject(prop: Record<any, any>) {
  let objeto = Object.assign({}, prop);
  for (let chave in objeto) {
    if (objeto[chave] === "" || objeto[chave] === "NaN") {
      objeto[chave] = null;
    } else if (Array.isArray(objeto[chave])) {
      for (let i = 0; i < objeto[chave].length; i++) {
        if (typeof objeto[chave][i] === "object" && objeto[chave][i] !== null) {
          cleanObject(objeto[chave][i]);
        }
      }
    } else if (typeof objeto[chave] === "object" && objeto[chave] !== null) {
      cleanObject(objeto[chave]);
    }
  }
  return objeto;
}
