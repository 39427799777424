import styled from "styled-components";
import Row from "../../containers/Row";

export const Container = styled(Row)`
  justify-content: space-between;
  padding: 0px 20px;
  min-height: 60px;
`;

export const CheckBox = styled.input`
  transform: scale(1.5);
  accent-color: ${(props) => props.theme.primary};
`;

