export default function Arrow(props: React.SVGAttributes<any>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        {...props}
      >
        <path  d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" fill="#323232"/>
      </svg>
    );
  }
  