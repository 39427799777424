/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";

const UpdateAssociate = async ({
  id,
  body,
}: {
  id: string;
  body: {
    name?: string;
    active?: boolean;
    birthDate?: string;
    email?: string;
    phone?: string;
    products?: number[];
  };
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/associates/${id}`,
  });
  try {
    const { data }: AxiosResponse<null> = await axiosProvider.put(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateAssociate;
