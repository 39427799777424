import { SVGProps } from "react";

const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="860"
    height="144"
    viewBox="0 0 860 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M84.0314 51.776V51.776C76.6171 60.4987 62.9457 60.5769 51.5698 59.2935C49.7476 59.0879 47.8697 58.9947 45.9324 58.9947C34.3021 58.9947 27.8855 62.6041 27.8855 68.8203C27.8855 75.237 33.7006 78.8463 46.3334 79.6484C64.9819 80.8516 88.6434 85.0625 88.6434 111.331C88.6434 128.776 74.4064 143.815 46.1329 143.815C30.4922 143.815 14.8516 141.208 0.414062 126.169V126.169C6.62641 117.161 19.9333 116.259 30.3035 119.751C35.8226 121.61 41.4547 122.468 46.5339 122.56C55.7579 122.76 64.3803 118.951 64.3803 111.732C64.3803 104.914 58.7657 101.104 44.7292 100.302C26.0808 98.8985 3.82292 92.0807 3.82292 69.6224C3.82292 46.763 27.4844 38.7421 45.5313 38.7421C60.9715 38.7421 72.6017 41.7499 84.0314 51.776Z"
      fill="#8F0CF2"
    />
    <path
      d="M118.936 40.8858C118.936 26.896 129.489 15.1593 143.4 13.6769V13.6769V41.7499H170.671V41.7499C170.671 53.3781 161.244 62.8046 149.616 62.8046H143.199V105.516C143.199 114.94 148.413 119.552 156.033 119.552C159.843 119.552 164.254 118.349 167.863 116.544L174.681 137.399C167.663 140.206 161.848 141.409 154.428 141.609C132.973 142.412 118.936 130.18 118.936 105.516V62.8046H100.488V60.1979C100.488 50.0093 108.748 41.7499 118.936 41.7499V41.7499V40.8858Z"
      fill="#8F0CF2"
    />
    <path
      d="M268.332 55.1848L269.134 41.5494V41.5494C282.092 41.5494 292.595 52.0532 292.595 65.0104V140.406H269.535L268.332 125.969C262.718 137.599 247.278 143.214 236.249 143.414C206.973 143.615 185.317 125.568 185.317 90.8776C185.317 56.789 207.975 38.9426 236.85 39.1431C250.085 39.1431 262.718 45.3593 268.332 55.1848ZM239.056 61C222.814 61 209.78 72.0286 209.78 90.8776C209.78 109.727 222.814 120.956 239.056 120.956C277.556 120.956 277.556 61 239.056 61Z"
      fill="#8F0CF2"
    />
    <path
      d="M319.003 64.2083C319.003 51.6941 329.148 41.5494 341.662 41.5494V41.5494L343.467 52.9791C351.086 40.7473 361.313 38.9426 371.339 38.9426C381.566 38.9426 391.391 42.953 396.805 48.3671L385.777 69.6224C380.764 65.4114 376.152 63.2057 368.131 63.2057C355.297 63.2057 343.467 70.0234 343.467 88.2708V140.406H319.003V64.2083Z"
      fill="#8F0CF2"
    />
    <path
      d="M463.203 49.1692C440.343 49.1692 422.698 66.013 422.698 90.6771C422.698 115.341 439.942 132.385 463.203 132.385C485.461 132.385 503.708 118.349 503.708 90.8776C503.708 63.0052 485.461 49.1692 463.203 49.1692ZM412.872 0.0415039H422.497V63.0052C430.518 46.9635 446.56 39.7447 463.604 39.7447C491.276 39.7447 513.333 58.1926 513.333 90.8776C513.333 123.763 491.476 142.011 463.804 142.011C446.961 142.011 430.718 133.589 422.497 118.55V140.406H412.872V0.0415039Z"
      fill="#5300B6"
    />
    <path
      d="M623.592 41.3489H633.417C633.417 74.4349 633.417 107.32 633.417 140.406H623.592V119.352C613.967 134.792 599.128 142.011 583.086 142.011C555.414 142.011 532.756 121.156 532.756 90.8776C532.756 60.5989 555.414 40.1458 583.086 40.1458C599.128 40.1458 615.37 47.565 623.592 63.0052V41.3489ZM583.086 49.5702C560.628 49.5702 542.581 66.013 542.581 90.8776C542.581 115.742 560.628 132.385 583.086 132.385C638.23 132.385 638.23 49.5702 583.086 49.5702Z"
      fill="#5300B6"
    />
    <path
      d="M737.259 52.9791L731.243 59.3958C721.217 50.5729 711.392 48.9687 700.363 48.9687C684.923 48.7682 670.084 54.5833 670.485 67.2161C670.886 80.4505 688.131 83.0573 700.564 85.263C718.209 88.2708 742.473 91.2787 741.47 114.339C740.868 136.195 718.209 142.011 700.764 142.011C683.319 142.011 666.074 135.393 657.452 122.159L664.67 115.742C672.892 127.573 687.73 132.586 700.965 132.586C712.996 132.586 731.243 129.378 731.845 113.737C732.246 99.5 715.803 96.6927 699.561 94.086C680.311 90.8776 661.261 87.2682 661.061 67.4166C660.86 47.9661 680.311 39.5442 700.363 39.7447C714.801 39.7447 727.433 43.7551 737.259 52.9791Z"
      fill="#5300B6"
    />
    <path
      d="M810.043 142.011C782.171 142.011 759.713 122.359 759.713 90.8776C759.713 61.2005 782.171 39.7447 810.043 39.7447C837.916 39.7447 862.781 56.789 858.971 95.4896H769.739C771.744 118.75 789.59 132.385 810.043 132.385C823.077 132.385 838.317 127.172 845.736 117.346L852.755 122.961C843.129 135.594 825.885 142.011 810.043 142.011ZM769.739 86.8672H850.148C849.947 62.4036 833.905 48.7682 810.043 48.7682C789.59 48.7682 771.944 62.6041 769.739 86.8672Z"
      fill="#5300B6"
    />
  </svg>
);
export default LogoIcon;
