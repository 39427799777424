/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import { useMutation, useQuery } from "@tanstack/react-query";
import Select from "../../../../../components/Select";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";
import Input from "../../../../../components/Input";
import { toast } from "react-toastify";

const ProductRule = ({ basic }: { basic: IBrandDetails }) => {
  const [rules, setRules] = useState("");
  const [details, setDetails] = useState<Record<string, any>>({
    productInfo: {},
  });

  const { data: products } = useQuery({
    queryKey: ["get-products-cinema", basic.id],
    queryFn: () => CinemaService.getProductsCinema(basic.id),
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["create-product-cinema"],
    mutationFn: CinemaService.updateProduct,
    onSuccess: () => {
      setRules("");
      setDetails({
        productInfo: {
          establishmentId: basic.id,
          name: "",
          rules: "",
          description: "",
          soldOutAmount: null,
          type: null,
          tags: [],
        },
      });
    },
  });

  const convertCurrencyStringToNumber = (currencyString: string) => {
    if (currencyString === "R$ 0,00") return "";
    return parseFloat(
      currencyString.replace("R$", "").replace(",", ".").trim()
    );
  };

  const init = () => {
    const object = {
      establishmentId: basic.id,
      name: "",
      rules: "",
      description: "",
      soldOutAmount: null,
      type: null,
      tags: [],
    };
    setDetails({ productInfo: object });
  };

  const update = () => {
    const body = {
      productInfo: {
        ...details.productInfo,
        price: convertCurrencyStringToNumber(details.productInfo.price),
        rules,
      },
    };

    mutate({ body });
    setDetails(details);
  };

  const disabledButton = useMemo(() => {
    if (!details.productInfo?.type || rules === details.productInfo?.rules)
      return true;
    return false;
  }, [details.productInfo?.type, details.productInfo?.rules, rules]);

  useEffect(() => {
    basic && init();
  }, [basic, products]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Regra alterada com sucesso");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Editar regras do produto"
      buttonProps={{
        disabled: disabledButton,
        loading: isPending,
        onClick: update,
      }}
      renderInsideComponents={() => (
        <>
          <Select
            required
            label="Tipo de Produto"
            placeholder="Selecione o tipo de produto"
            value={
              products?.data?.filter(
                (prod) => prod.productId === details.productInfo.productId
              ) || {}
            }
            data={products?.data || []}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.productId}
            onChange={(e: any) => {
              setRules(e.rules || "");
              setDetails((prev) => ({
                productInfo: {
                  ...prev.productInfo,
                  productId: e.productId,
                  type: e.type,
                  name: e.name,
                  description: e.description,
                  soldOutAmount: e.soldOutAmount,
                  price: e.price,
                  rules: e.rules,
                },
              }));
            }}
          />
          <Input
            name="rules"
            disabled={!details.productInfo.type}
            value={rules}
            type="textArea"
            placeholder="<p>Digite as regras do prduto<p/>"
            rows={7}
            label="Regras"
            onChange={(e) => {
              setRules(e.target.value);
            }}
          />
        </>
      )}
    />
  );
};

export default ProductRule;
