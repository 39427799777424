import { IRow } from "../../../components/Table/interface/IRow";
import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";
import { cnpjMask } from "../../../helpers/mask/cnpjMask";

export const customersTableConfig = (defaultProps: IRow = {}) => {
  const { onClick = () => {} } = defaultProps;
  return [
    {
      name: "ID",
      cell: (row: any) => (
        <RowId {...defaultProps} onClick={() => onClick(row)}>
          {row.id}
        </RowId>
      ),
    },
    {
      name: "Logo",

      cell: (row: any) => (
        <img
          {...defaultProps}
          onClick={() => onClick(row)}
          src={row.companiesImage.image}
          width={50}
          alt="logo"
          style={{ padding: "2px 0px" }}
        />
      ),
    },
    {
      name: "Cliente",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row?.name || row?.corporateName}{" "}
        </RowText>
      ),
      grow: 1.8,
    },
    {
      name: "CNPJ/CPF",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {cnpjMask(row.cnpj)}
        </RowText>
      ),
      grow: 1.3,
    },
    {
      name: "Segmento",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row?.segment.name}
        </RowText>
      ),
    },
    {
      name: " ",
      cell: (row: any) => (
        <RowStatus
          {...defaultProps}
          onClick={() => onClick(row)}
          status={row.active ? 1 : 0}
        />
      ),
    },
  ];
};
