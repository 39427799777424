import styled from "styled-components";
import Box from "../../containers/Box";

interface IContainer {
  width?: string;
}

export const Container = styled(Box)<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
`;

export const FloatLabel = styled.div`
  width: 30%;
  height: 40px;
  background-color: ${(props) => props.theme.primary};
  border-radius: 12px;
  position: absolute;
  top: -15px;
  left: 20%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
