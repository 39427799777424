import Button from "../buttons/Button";
import GenericText from "../texts/GenericText";
import { ICard } from "./ICard";
import { InfoCard } from "./styles";

const Card = (props: ICard) => {
  const { buttonProps = {} } = props;
  return (
    <InfoCard
      justifyContent={!props.removeButton ? "space-between" : "center"}
      {...props}
    >
      <GenericText
        font="Bold"
        size="h1"
        color={props.backgroundColor === "black" ? "#fff" : "#000"}
      >
        {props.title}
      </GenericText>
      <GenericText
        font="Regular"
        size="h4"
        color={props.backgroundColor === "black" ? "#fff" : "#000"}
      >
        {props.subTitle}
      </GenericText>
      {!props.removeButton && (
        <Button
          height={"35px"}
          margin={"10px 0px"}
          text="Ver mais"
          textProps={{ size: "h6", font: "Regular" }}
          {...buttonProps}
        />
      )}
    </InfoCard>
  );
};

export default Card;
