/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IProductType } from "./interface/IProductType";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetProductType = async (): Promise<IProductType> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/shop/product/type/get",
  });
  try {
    const { data }: AxiosResponse<IProductType> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetProductType;
