import styled from "styled-components";
import { IContainerProps } from "../IContainerProps";

const Column = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  ${(props) => props.margin && `margin:${props.margin};`}
  ${(props) => props.alignSelf && `align-self:${props.alignSelf};`}
  ${(props) => props.position && `position:${props.position};`}
`;

export default Column;
