/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { ICreateAssociate, ICreateAssociateParams } from "./interface/ICreateAssociate";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const CreateAssociate = async (body : ICreateAssociateParams): Promise<ICreateAssociate> => {
  const URL = queryFormatter({
    route: `dashboard/associates`,
  });
  try {
    const { data }: AxiosResponse<ICreateAssociate> = await axiosProvider.post(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default CreateAssociate;
