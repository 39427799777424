import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const FUpdateImages = async ({
  id,
  form,
  type,
}: {
  id: number | string;
  form: FormData;
  type: string;
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/company/${id}?type=${type}`,
  });

  try {
    const { data }: AxiosResponse<null> = await axiosProvider.post(URL, form);
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default FUpdateImages;
