import styled from "styled-components";
import { IInputProps } from "../IInput";

export const StyledInput = styled.input<IInputProps>`
  width: 100%;
  ${(props) => (props.height ? `height:${props.height}` : "")};
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 7px;
  font-family: "Noto Sans", sans-serif;
`;
