import styled from "styled-components";
import { IInputProps } from "./IInput";
import InputMask from "react-input-mask";

export const StyledInput = styled(InputMask)<IInputProps>`
  width: 100%;
  ${(props) => (props.height ? `height:${props.height}` : "")};
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 7px;
  font-family: "Noto Sans", sans-serif;
  line-height: 25px;
`;
