import { useMutation } from "@tanstack/react-query";

import { useEffect } from "react";
import { toast } from "react-toastify";
import Box from "../../../../components/containers/Box";
import UsersFetchs from "../../../../services/api/users/UsersFetchs";
import GenericText from "../../../../components/texts/GenericText";
import Column from "../../../../components/containers/Column";
import Button from "../../../../components/buttons/Button";
import { IUser } from "../../../../interfaces/users/IUser";

export default function ResetBox({ basic }: { basic: IUser }) {
  const { isPending, isSuccess, mutate } = useMutation({
    mutationKey: ["user-reset"],
    mutationFn: () => UsersFetchs.reset(basic.id),
  });
  useEffect(() => {
    if (!isSuccess) return;
    toast.success("Usuário resetado com sucesso!👌");
  }, [isSuccess]);
  return (
    <Box shadow>
      <Column>
        <GenericText size="h4" font="Bold">
          Resetar Usuário
        </GenericText>
        <GenericText font="Light" textAlign="center" margin={"20px 20px"}>
          Todos os dados e caches do usuário serão excluidos , inclusive o email
          permitindo que ele crie a sua conta novamente!
        </GenericText>
        <Button
          width="70%"
          height={"40px"}
          text="Executar ação irreversível!"
          textProps={{ size: "h6" }}
          color="red"
          loading={isPending}
          onClick={() => mutate()}
        />
      </Column>
    </Box>
  );
}
