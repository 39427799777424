/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import { IBrand } from "../../../../../services/api/cinema/interface/IBrand";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import Select from "../../../../../components/Select";
import Input from "../../../../../components/Input";
import { formatDate } from "../../../../../helpers/date/formatDate";
import { useEffect, useMemo, useState } from "react";
import changeStateByInputName from "../../../../../helpers/state/changeStateByInputName";
import Chip from "../../../../../components/Chip";
import { toast } from "react-toastify";
import { removeMaskDate } from "../../../../../helpers/date/removeMaskDate";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";

const VoucherInformationManual = ({ basic }: { basic: IBrandDetails }) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [details, setDetails] = useState<Record<string, any>>({});

  const { data: products } = useQuery({
    queryKey: ["get-products-cinema", basic.id],
    queryFn: () => CinemaService.getProductsCinema(basic.id),
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["create-voucher-cinema"],
    mutationFn: CinemaService.createVoucher,
  });

  const init = () => {
    const object = {
      establishmentId: basic.id,
      productId: null,
      date: "",
      batchId: "",
      vouchers: [],
    };
    setDetails(object);
  };

  const disabledButton = useMemo(() => {
    const { batchId, date, vouchers } = details;
    return !(batchId && date && vouchers?.length);
  }, [details]);

  const handleAddVoucher = () => {
    setDetails((prev) => ({
      ...prev,
      vouchers: [...prev.vouchers, voucherCode],
    }));

    setVoucherCode("");
  };

  const handleRemoveVoucher = (index: number) => {
    setDetails((prev) => ({
      ...prev,
      vouchers: prev.vouchers.filter(
        (_voucher: string, i: number) => i !== index
      ),
    }));
  };

  const update = () => {
    const { productId, batchId, date, vouchers, establishmentId } = details;

    mutate({
      batchId,
      date: removeMaskDate(date),
      establishmentId,
      productId,
      type: "body",
      body: { vouchers },
    });
  };

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    if (isSuccess) {
      const object = {
        establishmentId: basic.id,
        productId: null,
        date: "",
        batchId: "",
        vouchers: [],
      };
      toast.success("Vouchers adicionados com sucesso");
      setDetails(object);
    }
  }, [isSuccess]);

  return (
    <>
      <SubmitEditsForm
        title="Cadastrar vouchers manual"
        buttonProps={{
          disabled: disabledButton,
          loading: isPending,
          onClick: update,
        }}
        renderInsideComponents={() => (
          <>
            <Select
              required
              label="Produto"
              value={
                products?.data?.filter(
                  (product) => product.productId === details?.productId
                ) || 0
              }
              data={products?.data || []}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.productId}
              placeholder="Selecione o tipo de voucher"
              onChange={(e: any) => {
                setDetails((prev) => ({ ...prev, productId: e.productId }));
              }}
            />
            <Input
              required
              name="batchId"
              value={details.batchId}
              width={"100%"}
              label="Lote"
              type="text"
              margin={"5px 0px"}
              placeholder="Digite o lote "
              onChange={(e) => changeStateByInputName(e, setDetails)}
            />
            <Input
              required
              name="date"
              value={formatDate(details.date)}
              width="100%"
              label="Data de expiração código"
              placeholder="Digite a data de expiração "
              type="text"
              margin={"5px 0px"}
              onChange={(e) => changeStateByInputName(e, setDetails)}
            />

            <Input
              required
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddVoucher();
                }
              }}
              width={"100%"}
              label="Voucher ( Pressione a tecla ENTER )"
              placeholder="Pressione enter para cadastrar o voucher"
              type="text"
              margin={"5px 0px"}
            />
            <div>
              {details?.vouchers?.map((chip: string, index: number) => (
                <Chip
                  label={chip}
                  onDelete={() => handleRemoveVoucher(index)}
                />
              ))}
            </div>
          </>
        )}
      />
    </>
  );
};

export default VoucherInformationManual;
