/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IGeolocInformation } from "./interfaces/IGeolocInformation";

const FGeolocInformationByCep = async (
  cep?: string
): Promise<IGeolocInformation > => {
  const URL = queryFormatter({
    route: `openApi/cep/${cep}`,
  });

  try {
    const { data }: AxiosResponse<IGeolocInformation> = await axiosProvider.get(
      URL
    );
    return data;
  } catch (error: any) {
    throw error;
  }
};

export default FGeolocInformationByCep;
