/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { ISegment } from "../../../interfaces/customer/ICustomer";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetSegments = async (): Promise<ISegment[]> => {
  const URL = queryFormatter({
    route: "dashboard/company/segments",
    querys: {},
  });

  try {
    const { data }: AxiosResponse<ISegment[]> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetSegments;
