import { useLocation } from "react-router-dom";

function useQueryParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};

  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams as Record<string, string>;
}

export default useQueryParams;
