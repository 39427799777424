import CreateAssociate from "./Create";
import FSearch from "./FSearch";
import GetProductsByCompany from "./GetProductsByCompany";
import DetailsAssociate from "./DetailsAssociate";
import UpdateAssociate from "./UpdateAssociate";
import UpdateStatus from "./UpdateStatus";

class Service {
  search = FSearch;
  create = CreateAssociate;
  get = GetProductsByCompany;
  details = DetailsAssociate;
  updateAssociate = UpdateAssociate;
  updateStatus = UpdateStatus;
}

export default new Service();
