import Select, { GroupBase, StylesConfig } from "react-select";
import styled from "styled-components";

interface ISelect {
  width?: string;
}

export const SelectCustom = styled(Select)<ISelect>`
  width: ${(props) => (props.width ? props.width : "100%")};
  color: #000;
`;

export const customStyles: StylesConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  input: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "8px",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "13px",
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: "12px",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "13px",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#888",
    fontFamily: "Noto Sans, sans-serif",
    fontSize: "13px",
  }),
};
