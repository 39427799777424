import styled from "styled-components";
import Row from "../containers/Row";

export const Container = styled(Row)`
  width: 99%;
  justify-content: space-between;
  padding: 15px;
`;

export const BoxIcon = styled.div`
  background-color: ${(props) => props.theme.primary};
  border-radius: 50px;
  display: flex;
  align-items: center;
`;

export const Button = styled.button``;
