/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { ISumary } from "./interface/ISumary";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetSummary = async (): Promise<ISumary> => {
  const URL = queryFormatter({
    route: `dashboard/company/summary`,
  });
  try {
    const { data }: AxiosResponse<ISumary> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetSummary;
