import Column from "../../containers/Column";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { IInputProps } from "../IInput";
import { StyledInput } from "./styles";

const InputCurrency = (props: IInputProps) => {
  const { width, ...inputProps } = props;

  return (
    <Column width={width || "auto"} margin={props.margin || "0px"}>
      {props.label && (
        <Row justifyContent="flex-start" margin="0px 0px 0.4rem 0px">
          <GenericText size="h6">{props.label}</GenericText>
        </Row>
      )}
      <StyledInput {...inputProps} />
    </Column>
  );
};

export default InputCurrency;
