import { useTheme } from "styled-components";
import Box from "../containers/Box";
import Column from "../containers/Column";
import GenericText from "../texts/GenericText";
import { IBoxAction } from "./IBoxAction";
import { Description } from "./styles";

const BoxAction = (props: IBoxAction) => {
  const theme = useTheme();
  return (
    <Box shadow width={props.width || "100%"} style={{ padding:  "30px 0px" }}>
      <Column alignItems="center">
        <Column width={"90%"}>
          <GenericText size="h5" font="Bold">
            {props.title}
          </GenericText>
          <div style={{ width: "100%" }}>
            <Description margin="15px 0px" size="h7" font="Light">
              {props.description}
            </Description>
          </div>
        </Column>
        {props.renderActionButton()}
        {props.renderExampleFile && (
          <GenericText
            color={theme.primary}
            margin="15px 0px  0px"
            underline
            cursor="pointer"
            size="h7"
            children={
              <a
                href={
                  props.renderExampleFile.type === "inative"
                    ? "https://node.stations.cloud/images/excel/exemplo-inativar.xlsx"
                    : "https://node.stations.cloud/images/excel/exemplo-associados.xlsx"
                }
              >
                {props.renderExampleFile.textFile}
              </a>
            }
          />
        )}
      </Column>
    </Box>
  );
};

export default BoxAction;
