import { useEffect, useMemo, useState } from "react";
import Input from "../../../../../components/Input";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import { currencyMask } from "../../../../../helpers/mask/currencyMask";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import Select from "../../../../../components/Select";
import useVerifyObjectEqual from "../../../../../hooks/useVerifyObjectEqual";
import { days } from "./days";
import convertEmptyStringsToNullOrDelete from "../../../../../helpers/object/convertEmptyStringsToNull ";
import { toast } from "react-toastify";

export default function FinancialInformation({ id }: { id: number }) {
  const { data: dataCustomer } = useQuery({
    queryKey: ["company-customers-details"],
    queryFn: () => CustomerService.getCompany({ limit: 20 }),
  });

  const { mutate, isPending, isSuccess} = useMutation({
    mutationKey: ["update-financial-customer-details"],
    mutationFn: ({ id, body }: { id: number; body: any }) =>
      CustomerService.updateFinancial({ id, body }),
  });

  const [minBill, setMinBill] = useState("");
  const [fixedBill, setFixedBill] = useState("");
  const [unitValue, setUniValue] = useState("");

  const [initialObject, setInitialObject] = useState({
    unitValue,
    iss: "",
    dueDate: "",
    minBill,
    fixedBill,
    motherId: "",
    childrensIds: [],
  });

  const [financial, setFinancial] = useState({
    unitValue,
    iss: "",
    dueDate: "",
    minBill,
    fixedBill,
    motherId: "",
    childrensIds: [],
  });

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: financial,
  });

  const changeStateFinancial = (e: any) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFinancial((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const convertCurrencyStringToNumber = (currencyString: string) => {
    if (currencyString === "R$ 0,00") return "";
    return parseFloat(
      currencyString.replace("R$", "").replace(",", ".").trim()
    );
  };

  const formateDataCustomer = useMemo(() => {
    if (dataCustomer?.data) {
      return dataCustomer.data.map((customer) => ({
        name: customer.name,
        id: customer.id,
      }));
    }
    return [];
  }, [dataCustomer?.data]);

  const update = () => {
    const updatedFinancial = convertEmptyStringsToNullOrDelete(
      {
        ...financial,
        iss: financial.iss.replace("%", ""),
      },
      true
    );
    mutate({ id, body: updatedFinancial });
    setInitialObject(financial);
  };
  useEffect(() => {
    const convertedMinBill = minBill.length
      ? convertCurrencyStringToNumber(minBill)
      : "";
    const convertedFixedBill = fixedBill.length
      ? convertCurrencyStringToNumber(fixedBill)
      : "";
    const convertedUnitValue = unitValue.length
      ? convertCurrencyStringToNumber(unitValue)
      : "";
    setFinancial((prev) => ({
      ...prev,
      minBill: convertedMinBill.toString(),
      fixedBill: convertedFixedBill.toString(),
      unitValue: convertedUnitValue.toString(),
    }));
  }, [minBill, fixedBill, unitValue]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso")
    }
  }, [isSuccess])

  return (
    <SubmitEditsForm
      title="Financeiro"
      buttonProps={{
        onClick: update,
        loading: isPending,
        disabled: disabledButton,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="unitValue"
            value={unitValue}
            width={"100%"}
            label="Valor Unitário"
            placeholder="R$ 0,00"
            type="currency"
            margin={"5px 0px"}
            onChange={({ target }) =>
              currencyMask({ target, setValue: setUniValue })
            }
          />
          <Input
            name="minBill"
            value={minBill}
            width={"100%"}
            label="Boleto Mínimo"
            placeholder="R$ 0,00"
            type="text"
            margin={"5px 0px"}
            onChange={({ target }) =>
              currencyMask({ target, setValue: setMinBill })
            }
          />
          <Input
            value={fixedBill}
            name="fixedBill"
            width={"100%"}
            label="Boleto fixo"
            placeholder="R$ 0,00"
            type="text"
            margin={"5px 0px"}
            onChange={({ target }) =>
              currencyMask({ target, setValue: setFixedBill })
            }
          />
          <Input
            name="iss"
            value={financial.iss}
            width={"100%"}
            label="ISS"
            type="percentage"
            margin={"5px 0px"}
            placeholder="0%"
            onChange={(e) => changeStateFinancial(e)}
          />
          <Select
            data={days}
            name="dueDate"
            width={"100%"}
            label="Dia de Pagamento"
            placeholder="Selecione o dia de pagamento"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            onChange={(e: any) => {
              setFinancial((prev) => ({
                ...prev,
                dueDate: e.id,
              }));
            }}
          />
          <Select
            label="Empresa Mãe"
            placeholder="Selecione a empresa mae"
            data={formateDataCustomer}
            onChange={(e: any) => {
              setFinancial((prev) => ({
                ...prev,
                motherId: e.id,
              }));
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
          />
          <Select
            label="Empresa Filha"
            placeholder="Selecione as empresas filhas"
            data={formateDataCustomer}
            onChange={(e: any) => {
              const ids = e ? e.map((option: any) => option.id) : [];
              setFinancial((prev) => ({
                ...prev,
                childrensIds: ids,
              }));
            }}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            isMulti
          />
        </>
      )}
    />
  );
}
