import styled from "styled-components";
import { IInputSearch } from "./IInputSearch";
export const StyledInput = styled.input<IInputSearch>`
  width: 100%;
  ${(props) => (props.height ? `height:${props.height}` : "")};
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid;
  border-color: ${(props) => props.theme.primary};
  border-radius: 4px;
  border-radius: 15px;
  font-family: "Noto Sans", sans-serif;
`;
