import { useEffect, useMemo, useState } from "react";
import EditImage from "../../../../../components/Exotics/EditImage";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import { useMutation } from "@tanstack/react-query";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import { toast } from "react-toastify";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";

const ImagesInformation = ({ basic }: { basic: IBrandDetails }) => {
  const [logo, setLogo] = useState(basic.images?.logo);
  const [cover, setCover] = useState(basic.images?.cover);
  const [logoBlob, setLogoBlob] = useState<Blob | null>(null);
  const [coverBlob, setCoverBlob] = useState<Blob | null>(null);

  const disabledButton = useMemo(
    () => !(logoBlob || coverBlob),
    [logoBlob, coverBlob]
  );

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["update-cinema-images"],
    mutationFn: CinemaService.updateImages,
  });

  const update = async () => {
    let arrayPromises = [];
    if (logoBlob) {
      let logoForm = new FormData();
      logoForm.append("file", logoBlob);

      arrayPromises.push(
        mutate({
          establishmentId: basic.id,
          imageType: "logo",
          form: logoForm,
        })
      );
    }
    if (coverBlob) {
      let coverForm = new FormData();
      coverForm.append("file", coverBlob);
      arrayPromises.push(
        mutate({
          establishmentId: basic.id,
          imageType: "cover",
          form: coverForm,
        })
      );
    }
    await Promise.all(arrayPromises);
    setCoverBlob(null);
    setLogoBlob(null);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Imagem salva com sucesso! 👌");
    }
  }, [isSuccess]);
  return (
    <>
      <SubmitEditsForm
        title="Imagens"
        buttonProps={{
          disabled: disabledButton,
          onClick: update,
          loading: isPending,
        }}
        renderInsideComponents={() => (
          <>
            <EditImage
              title={"Logo"}
              onChange={(blob) => {
                setLogo(URL.createObjectURL(blob));
                setLogoBlob(blob);
              }}
              image={logo}
              width="250px"
            />
            <EditImage
              title={"Fundo"}
              onChange={(blob) => {
                setCover(URL.createObjectURL(blob));
                setCoverBlob(blob);
              }}
              image={cover}
            />
          </>
        )}
      />
    </>
  );
};

export default ImagesInformation;
