import GenericText from "../../texts/GenericText";
import { IRow } from "../interface/IRow";

const RowText = (props: IRow) => {
  const { children } = props;

  if (children && children.length > 20) {
    return (
      <div {...props} title={children}>
        <GenericText font="Regular" size={"h6"}>
          {children}
        </GenericText>
      </div>
    );
  }
  return (
    <div {...props} title={children}>
      <GenericText font="Regular" size={"h6"}>
        {children}
      </GenericText>
    </div>
  );
};

export default RowText;
