/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const UpdateProducts = async ({
  id,
  body,
}: {
  id: number;
  body: { products: number[] };
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/company/products/replace/${id}`,
  });

  try {
    const { data }: AxiosResponse<null> = await axiosProvider.post(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateProducts;
