import { useTheme } from "styled-components";
import { Seprator } from "../../Exotics/Seprator";
import SVG from "../../Images/Svg";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { CheckBox, Container } from "./styles";

interface ICheckBoxImage extends React.InputHTMLAttributes<HTMLInputElement> {
  image: string;
  width?: string | number;
}

const CheckBoxImage = ({ image, width, ...props }: ICheckBoxImage) => {
  const theme = useTheme();
  return (
    <>
      <Container width={width}>
        <Row width="auto" alignItems="center">
          <SVG
            src={image}
            alt="Descrição da Imagem"
            color={theme.primary}
            width="25px"
            replaceColor="#569A59"
          />
          <GenericText margin="0px 0px 0px 10px">{props.alt}</GenericText>
        </Row>

        <CheckBox type="checkbox" {...props} />
      </Container>
      <Seprator />
    </>
  );
};
export default CheckBoxImage;
