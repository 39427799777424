/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { ISearchUsers } from "./interfaces/ISearchUsers";
import { IUser } from "../../../interfaces/users/IUser";

const FSearch = async (
  props: ISearchUsers = {}
): Promise<{ data: IUser[]; count: number }> => {
  const URL = queryFormatter({
    route: `dashboard/app/users`,
    querys: {
      limit: props.limit,
      page: (props.page ?? 1) - 1,
      name: props.search,
      companyId: props.companyId,
    },
  });
  try {
    const { data }: AxiosResponse<{ data: IUser[]; count: number }> =
      await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FSearch;
