/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import AuthenticationFetchs from "../../../services/api/authentication/AuthenticationFetchs";
import { ILoginController } from "./interfaces/ILoginController";

export default function useLoginController(setToken: (token: string) => void) {
  const navigate = useNavigate();
  const { mutate, isError, error, isSuccess, data, isPending } = useMutation({
    mutationFn: AuthenticationFetchs.login,
    mutationKey: ["login"],
  });
  async function executeLogin(data: { login: string; password: string }) {
    mutate(data);
  }
  useEffect(() => {
    if (isSuccess) {
      setToken(data?.token);
      navigate("/clientes/home");
    }
    if (isError) {
      const errorMessage = error?.message || "Ocorreu um erro ao fazer login.";
      toast.error(errorMessage);
    }
  }, [isSuccess, isError, error]);

  const response: ILoginController = useMemo(
    () => ({
      login: executeLogin,
      loading: isPending,
    }),
    [isPending, data?.token]
  );

  return response;
}
