import RowId from "../../../components/Table/RowId";
import RowStatus from "../../../components/Table/RowStatus";
import RowText from "../../../components/Table/RowText";
import { IRow } from "../../../components/Table/interface/IRow";

export const cinemaHome = (defaultProps: IRow = {}) => {
  const { onClick = () => {} } = defaultProps;
  return [
    {
      name: "ID",
      cell: (row: any) => (
        <RowId {...defaultProps} onClick={() => onClick(row)}>
          {row.id}
        </RowId>
      ),
    },
    {
      name: "Logo",
      cell: (row: any) => (
        <img
          {...defaultProps}
          src={row.images.logo}
          width={50}
          alt="logo"
          style={{ padding: "2px 0px" }}
          onClick={() => onClick(row)}
        />
      ),
    },
    {
      name: "Nome",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.name}
        </RowText>
      ),
      grow: 1.8,
    },
    {
      name: "Ingressos disponíveis",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.availableTickets ? "Sim" : "Não"}
        </RowText>
      ),
    },
    {
      name: "Consumíveis disponíveis",
      cell: (row: any) => (
        <RowText {...defaultProps} onClick={() => onClick(row)}>
          {row.availableConsumables ? "Sim" : "Não"}
        </RowText>
      ),
    },
    {
      name: " ",
      cell: (row: any) => (
        <RowStatus
          {...defaultProps}
          status={row.active ? 1 : 0}
          onClick={() => onClick(row)}
        />
      ),
    },
  ];
};
