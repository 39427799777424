/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const PostExcel = async ({
  type,
  formData,
}: {
  type: "active" | "inative";
  formData: FormData;
}): Promise<void> => {
  const URL = queryFormatter({
    route:
      type === "active"
        ? "dashboard/associates/excel"
        : "dashboard/associates/excel/inactive",
  });
  try {
    const { data }: AxiosResponse<null> = await axiosProvider.post(
      URL,
      formData
    );
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default PostExcel;
