import { Navigate } from "react-router-dom";
import UseAuth from "../hooks/useAuth";

const Public = ({ children }: { children: any }) => {
  const { token } = UseAuth();

  return token ? children : <Navigate to="/autenticacao/login" />;
};

export default Public;
