import { useEffect, useState } from "react";
import { ICustomer } from "../../../../interfaces/customer/ICustomer";
import { useMutation } from "@tanstack/react-query";
import CustomerService from "../../../../services/api/customer/CustomerService";
import { toast } from "react-toastify";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";
import EditImage from "../../../../components/Exotics/EditImage";

const ImagesInformation = ({ basic }: { basic: ICustomer }) => {
  const [frontUrl, setFrontUrl] = useState(basic.companiesImage.frontOfCard);
  const [backUrl, setBackUrl] = useState(basic.companiesImage.backOfCard);

  const [backBlob, setBackBlob] = useState<Blob | null>(null);
  const [frontBlob, setFrontBlob] = useState<Blob | null>(null);

  function checkDisabled() {
    if (backBlob || frontBlob) return false;
    return true;
  }

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationKey: ["customer-update-image"],
    mutationFn: CustomerService.updateImages,
  });

  async function updateImages() {
    let arrayPromises = [];
    if (frontBlob) {
      let frontForm = new FormData();
      frontForm.append("file", frontBlob);
      arrayPromises.push(
        mutateAsync({ id: basic.id, type: "front", form: frontForm })
      );
    }
    if (backBlob) {
      let backForm = new FormData();
      backForm.append("file", backBlob);
      arrayPromises.push(
        mutateAsync({ id: basic.id, type: "back", form: backForm })
      );
    }
    await Promise.all(arrayPromises);
    setBackBlob(null);
    setFrontBlob(null);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success("Dados salvos com sucesso");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Imagens"
      buttonProps={{
        disabled: checkDisabled(),
        onClick: () => updateImages(),
        loading: isPending,
      }}
      renderInsideComponents={() => (
        <>
          <EditImage
            title="Frente do cartão"
            image={frontUrl}
            onChange={(blob) => {
              setFrontUrl(URL.createObjectURL(blob));
              setFrontBlob(blob);
            }}
          />
          <EditImage
            title="Verso do cartão"
            image={backUrl}
            onChange={(blob) => {
              setBackBlob(blob);
              setBackUrl(URL.createObjectURL(blob));
            }}
          />
        </>
      )}
    />
  );
};

export default ImagesInformation;
