/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IQueryAssocites } from "../../../interfaces/associates/IQueryAssocites";
import { IAssociate } from "../../../interfaces/associates/IAssociate";

const FSearch = async (props: IQueryAssocites = {}): Promise<IAssociate[]> => {
  const URL = queryFormatter({
    route: `dashboard/associates`,
    querys: {
      limit: props.limit,
      page: (props.page ?? 1) - 1,
      name: props.name,
      email: props.email,
      cpf: props.cpf,
      companyId: props.companyId,
      active: props.active,
    },
  });
  try {
    const { data }: AxiosResponse<IAssociate[]> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FSearch;
