import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const updateOrCreateTokenMvno = async ({
  id,
  update,
}: {
  id: number | string;
  update: Record<string, any>;
}): Promise<void> => {
  const URL = queryFormatter({
    route: `mvno/dashboard/company/${id}`,
  });

  try {
    const { data }: AxiosResponse<null> = await axiosProvider.put(URL, update);
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default updateOrCreateTokenMvno;
