/* eslint-disable import/no-anonymous-default-export */
import GetPlanId from "./GetPlanId";
import GetPlans from "./GetPlans";
import GetPlansSummary from "./GetPlansSummary";
import UpdatePlan from "./UpdatePlan";

class service {
  getPlans = GetPlans;
  getPlanId = GetPlanId;
  getPlansSummary = GetPlansSummary;
  updatePlan = UpdatePlan;
}

export default new service();
