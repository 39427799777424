import Select from "..";
import GenericText from "../../texts/GenericText";
import { IBoxSelect } from "./IBoxSelec";
import { Container, FloatLabel } from "./styles";

const BoxSelect = (props: IBoxSelect) => {
  const { fatherWidth, labelContainer } = props;
  return (
    <Container width={fatherWidth}>
      {labelContainer && (
        <FloatLabel>
          <GenericText font="Regular" size="h6" color="white">
            {labelContainer}
          </GenericText>
        </FloatLabel>
      )}
      <div style={{ width: props.width }}>
        <Select {...props} />
      </div>
    </Container>
  );
};

export default BoxSelect;
