/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

interface ISummaryProduct {
  active: number;
  inactive: number;
  soldOut: number;
  total: number;
}

const GetSummaryProducts = async (
  establishmentId: number
): Promise<ISummaryProduct> => {
  const URL = queryFormatter({
    route: `cinema/dashboard/shop/count`,
    querys: { establishmentId },
  });
  try {
    const { data }: AxiosResponse<ISummaryProduct> = await axiosProvider.get(
      URL
    );
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetSummaryProducts;
