/* eslint-disable no-throw-literal */
import queryFormatter from "../../utils/queryFormatter";
import { IPlan } from "./interface/IPlans";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const UpdatePlan = async (
  body: Omit<IPlan, "companyId, information">
): Promise<void> => {
  const URL = queryFormatter({
    route: "plan/dashboard/admin/plan",
  });
  try {
    await axiosProvider.patch(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdatePlan;
