/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IVoucher } from "./interface/IVoucher";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

interface IGetVoucher {
  productId?: number;
  establishmentId: number;
  search?: string;
  page: number;
  limit: number;
}

const GetVouchers = async ({
  limit,
  page,
  establishmentId,
  productId,
  search,
}: IGetVoucher): Promise<IVoucher> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/voucher/get",
    querys: {
      limit,
      page: (page ?? 1) - 1,
      establishmentId,
      productId,
      search,
    },
  });
  try {
    const { data }: AxiosResponse<IVoucher> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetVouchers;
