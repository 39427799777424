function CrookedPolygon(props: React.SVGAttributes<any>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9937 17.3952C16.4809 17.7937 17.8417 16.4329 17.4432 14.9457L13.8778 1.63944C13.4793 0.1523 11.6204 -0.345795 10.5317 0.742867L0.790839 10.4838C-0.297823 11.5724 0.200277 13.4313 1.68742 13.8298L14.9937 17.3952Z"
        fill={props.color || "#000"}
      />
    </svg>
  );
}

export default CrookedPolygon;
