export const FontSize = {
  h1: 26,
  h2: 22,
  h3: 19,
  h4: 18,
  h5: 16,
  h6: 14,
  h7: 12,
  h8: 10,
  h9: 8,
};
