/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";

const UpdateImages = async ({
  establishmentId,
  imageType,
  form,
}: {
  establishmentId: number;
  imageType: "cover" | "logo";
  form: FormData;
}): Promise<void> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/establishment/image/edit",
    querys: { establishmentId, imageType },
  });

  try {
    await axiosProvider.put(URL, form);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateImages;
