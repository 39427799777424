import styled from "styled-components";
import { SketchPicker } from "react-color";

export const ColorPicker = styled(SketchPicker)`
  position: absolute;
  top: 30px;
  right: 10px;
`;

export const ColorClicker = styled.div<{ value: string }>`
  width: 20%;
  height: 35px;
  border-radius: 7px;
  background-color: ${(props) => props.value};
  position: absolute;
  top: 23px;
  right: 5px;
  cursor: pointer;
`;
