import Column from "../../containers/Column";
import GenericText from "../../texts/GenericText";
import { IInputProps } from "../IInput";
import { BoxTextArea, TextArea } from "./styles";

const InputTextArea = (props: IInputProps) => {
  return (
    <Column margin={props?.margin || "0px"}>
      <GenericText alignSelf="flex-start" margin={"0px 0px 5px 0px"}>
        {props.label}
      </GenericText>
      <BoxTextArea>
        <TextArea {...props} />
      </BoxTextArea>
    </Column>
  );
};

export default InputTextArea;
