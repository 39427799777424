import styled from "styled-components";
import Button from "../buttons/Button";
import Column from "../containers/Column";

interface IInfoCard {
  backgroundColor: string;
}

export const InfoCard = styled(Column)<IInfoCard>`
  justify-content: ${(props) => props.justifyContent || "space-between"};
  background-color: ${(props) => props.backgroundColor};
  width: 300px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  border-radius: 10px;
  min-height: 110px;
`;
export const Btn = styled(Button)`
  width: 33%;
  margin-bottom: 11px;
  display: flex;
  border-radius: 15px;
  margin-left: auto;
`;
