/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useVerifyObjectEqual from "../../../../hooks/useVerifyObjectEqual";
import { IAssociate } from "../../../../interfaces/associates/IAssociate";
import Row from "../../../../components/containers/Row";
import Input from "../../../../components/Input";
import changeStateByInputName from "../../../../helpers/state/changeStateByInputName";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";

export default function CustomerBox({ basic }: { basic: IAssociate  }) {
  const [details, setDetails] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState({});

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: details,
  });

  function init() {
    const object = {
      id: basic.company.id,
      name: basic.company.name,
      cnpj: basic.company.cnpj,
      image: basic.company.companyImage.image,
    };
    setDetails(object);
    setInitialObject(object);
  }

  async function update() {
    setInitialObject(details);
  }

  useEffect(() => {
    basic && init();
  }, [basic]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success("Dados salvos com sucesso");
  //   }
  // }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Empresa"
      buttonProps={{
        onClick: update,
        disabled: disabledButton,
      }}
      renderInsideComponents={() => (
        <>
          <Row>
            <img
              alt="Companie-Brand"
              src={details.image}
              width={"100px"}
              height={"80px"}
            />
          </Row>
          <Input
            name="id"
            value={details.id}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            width={"100%"}
            label="id"
            type="text"
            margin={"5px 0px"}
            disabled
          />
          <Input
            name="name"
            value={details.name}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            width={"100%"}
            label="Nome"
            type="text"
            margin={"5px 0px"}
            disabled
          />
          <Input
            maskType="cnpj"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="cnpj"
            value={details.cnpj}
            width={"100%"}
            label="Cnpj"
            type="text"
            disabled
            margin={"5px 0px"}
          />
        </>
      )}
    />
  );
}
