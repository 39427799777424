/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IPush } from "../../../interfaces/Push/INotification";

const FCreate = async (body: IPush): Promise<IPush> => {
  const URL = queryFormatter({
    route: `dashboard/notify`,
  });
  try {
    const { data }: AxiosResponse<IPush> = await axiosProvider.post(URL, body);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FCreate;
