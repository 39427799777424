import { useQuery } from "@tanstack/react-query";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";
import { useState } from "react";
import useDebounce from "../../../../../../helpers/hooks/useDebounce";
import CustomerService from "../../../../../../services/api/customer/CustomerService";

export default function FirstStepPush(props: {
  push: Record<string, any>;
  changePush: (e: any) => void;
}) {
  const [selectInput, setSelectInput] = useState("");
  const debounceInputName = useDebounce(selectInput, 1000);
  const { data: customers } = useQuery({
    queryKey: ["customers-query", debounceInputName],
    queryFn: () =>
      CustomerService.getCompany({
        limit: 20,
        page: 1,
        name: debounceInputName,
      }),
  });
  function changeSelect(v: any) {
    props.changePush((el: any) => ({ ...el, companyId: v.id }));
  }
  function changeHeaderObject(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    props.changePush((el: any) => ({
      ...el,
      header: { ...el?.header, [e.target.name]: e.target.value },
    }));
  }
  function getSelectValue() {
    if (props.push.companyId === undefined) return;
    if (props.push.companyId === null) return { name: "Todas", id: null };
    if (customers?.data)
      return customers.data.find((el) => el.id === props.push.companyId);
  }
  return (
    <>
      <Select
        onInputChange={(e) => setSelectInput(e)}
        label="Empresa"
        placeholder={"Escolha o cliente para onde enviar"}
        data={
          customers?.data
            ? [{ name: "Todas", id: null }, ...customers?.data]
            : []
        }
        getOptionLabel={(e: any) => e.name}
        getOptionValue={(e: any) => e.id}
        onChange={changeSelect}
        value={getSelectValue()}
        required
      />
      <Input
        name="title"
        label="Titulo da notificação"
        value={props.push?.header?.title}
        onChange={changeHeaderObject}
        placeholder="Digite o titulo para a notificação"
        required
      />
      <Input
        name="description"
        label="Descrição da notificação"
        value={props.push?.header?.description}
        onChange={changeHeaderObject}
        placeholder="Digite o descrição para a notificação"
        required
      />
    </>
  );
}
