import { Seprator } from "../Exotics/Seprator";
import GenericText from "../texts/GenericText";
import { BoxIcon, Container } from "./styles";
import ProfileIcon from "../../assets/Icons/ProfileIcon";
import Row from "../containers/Row";
import LogoutIcon from "../../assets/Icons/LogoutIcon";
import ButtonIcon from "../buttons/ButtonIcon";
import useAuth from "../../hooks/useAuth";

const Header = () => {
  const { logout, profile } = useAuth();

  const getRouteFromUrl = (url: string): string => {
    try {
      const urlObj = new URL(url);
      const hash = urlObj.hash;
      let route = "";

      if (hash.startsWith("#/")) {
        route = hash.substring(2); // Remove o "#/"
        const routeWithoutParams = route.split("?")[0]; // Remove queryParams, se houver
        return routeWithoutParams
          .split("/")
          .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
          .join(" / ");
      }
      return "";
    } catch (error) {
      console.error("Invalid URL:", error);
      return "";
    }
  };

  const currentRoute = getRouteFromUrl(window.location.href);

  return (
    <>
      <Container>
        <GenericText font="Regular" size="h2">
          {currentRoute}
        </GenericText>
        <Row width="auto" alignItems="center">
          <BoxIcon>
            <ProfileIcon />
          </BoxIcon>
          <GenericText margin="0px 15px 0px 5px" font="Regular" size="h2">
            Olá, {profile?.name}
          </GenericText>
          <ButtonIcon onClick={logout} width="auto">
            <LogoutIcon />
          </ButtonIcon>
        </Row>
      </Container>
      <Seprator />
    </>
  );
};

export default Header;
