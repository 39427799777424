/* eslint-disable no-throw-literal */
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { IBrandDetails } from "./interface/IBrandDetails";

const UpdateCinema = async (
  body: Omit<IBrandDetails, "images">
): Promise<void> => {
  const URL = queryFormatter({
    route: `cinema/dashboard/establishment/brand/edit`,
  });
  try {
    await axiosProvider.patch(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateCinema;
