import styled from "styled-components";
import { IContainerProps } from "../IContainerProps";

const Row = styled.div<IContainerProps>`
  display: flex;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  ${(props) => props.margin && `margin:${props.margin};`}
  ${(props) => props.alignSelf && `align-self:${props.alignSelf};`}
    ${(props) => props.wrap && `flex-wrap:${props.wrap};`}
    ${(props) => props.position && `position:${props.position};`}
`;

export default Row;
