/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";

const CreateProduct = async ({
  body,
}: {
  body: {
    productInfo: {
      establishmentId: number;
      name: string;
      rules?: string;
      description?: string;
      soldOutAmount?: number;
      tags: number[];
    };
  };
}): Promise<void> => {
  const URL = queryFormatter({
    route: `cinema/dashboard/shop/product/create`,
  });

  try {
    await axiosProvider.post(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default CreateProduct;
