/* eslint-disable no-throw-literal */

import { AxiosResponse } from "axios";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { IBrandDetails } from "./interface/IBrandDetails";

const GetBrandDetails = async (id: number): Promise<IBrandDetails> => {
  const URL = queryFormatter({
    route: `cinema/dashboard/establishment/brand/get/${id}`,
  });

  try {
    const { data }: AxiosResponse<IBrandDetails> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetBrandDetails;
