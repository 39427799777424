import Header from "../../../Header";
import Navbar from "../../../NavBar";
import { Container, Section } from "./styles";

const Wrapper = ({ children }: { children: any }) => {
  return (
    <>
      <Navbar />
      <Container>
        <Header />
        <Section>{children}</Section>
      </Container>
    </>
  );
};

export default Wrapper;
