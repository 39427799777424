/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { IGetProductsByCompany, IGetProductsByCompanyParams } from "./interface/ICreateAssociate";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetProductsByCompany = async (id : string | number): Promise<IGetProductsByCompany[]> => {
  const URL = queryFormatter({
    route: `dashboard/products?company=${id}`,
    querys: { },
  });

  try {
    const { data }: AxiosResponse<IGetProductsByCompany[]> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetProductsByCompany ;
