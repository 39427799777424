import styled from "styled-components";
import Column from "../../containers/Column";

export const SEFContainer = styled(Column)`
  padding: 10px;
  box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.2);
  min-width: 170px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: auto;
  position: relative;
  flex: 1;
`;
