import React from "react";

function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 62 62"
    >
      <path
        stroke="#fff"
        strokeWidth="3.777"
        d="M39.32 15.702a8.183 8.183 0 11-16.367 0 8.183 8.183 0 0116.366 0zM46.872 43.398c0 2.708-1.533 5.338-4.371 7.365-2.834 2.025-6.846 3.336-11.365 3.336s-8.531-1.311-11.365-3.336c-2.838-2.027-4.372-4.657-4.372-7.365s1.534-5.339 4.372-7.365c2.834-2.025 6.846-3.336 11.365-3.336s8.53 1.311 11.365 3.336c2.838 2.026 4.371 4.657 4.371 7.365z"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
