/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

interface IBody {
  unitValue: string;
  iss: string;
  dueDate: string;
  minBill: string;
  fixedBill: string;
  motherId: string;
  childrensIds: string[];
}

const UpdateFinancial = async ({
  id,
  body,
}: {
  id: number;
  body: IBody;
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/financial/bill/${id}`,
  });

  try {
    const { data }: AxiosResponse<null> = await axiosProvider.put(URL, body);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateFinancial;
