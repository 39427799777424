
export default function checkSecondStep(object: Record<string, any>) {
  const body = object.body;
  if (!body?.components?.pageTitle || !body?.title || !body?.description) {
    return true;
  }
  const button = body.components.button;
  if (typeof object.checkButton !== "boolean") return true;
  if (
    object.checkButton &&
    (!button?.text || !button?.target || !button?.type)
  ) {
    return true;
  }
  return false;
}
