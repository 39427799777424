import { useEffect, useMemo, useState } from "react";
import { StorageNames } from "../../../constants/storage/StorageNames";
import { IChangeTokenOptions } from "./interfaces/IChangeTokenOptions";
import { axiosProvider } from "../../../services/utils/axiosProvider";
import { ITokenController } from "./interfaces/ITokenController";
import { IProfile } from "../../../interfaces/authentication/IProfile";
import AuthenticationFetchs from "../../../services/api/authentication/AuthenticationFetchs";

export default function useTokenController() {
  const [removeDoubleFetch, setRemoveDoubleFetch] = useState(true);
  const [profile, setProfile] = useState<IProfile>({} as IProfile);
  const [token, setToken] = useState(
    localStorage.getItem(StorageNames.token) || ""
  );
  axiosProvider.defaults.headers.common["Authorization"] = token;
  async function changeToken(
    token: string,
    options: IChangeTokenOptions = {}
  ): Promise<void> {
    if (options.logout) {
      localStorage.removeItem(StorageNames.token);
      setToken("");
      return;
    }
    localStorage.setItem(StorageNames.token, String(token));
    setToken(token);
  }
  function logout() {
    changeToken("", { logout: true });
  }
  async function getProfile() {
    const data = await AuthenticationFetchs.profile();
    setProfile(data);
  }
  useEffect(() => {
    if (!token) return;
    if (removeDoubleFetch) {
      setRemoveDoubleFetch(false);
      return;
    }
    getProfile();
  }, [token , removeDoubleFetch]);

  const response: ITokenController = useMemo(
    () => ({ token, changeToken, logout, profile }),
    [token, profile]
  );

  return response;
}
