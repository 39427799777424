import { getMaskType } from "../../helpers/mask/getMaskType";
import Column from "../containers/Column";
import Row from "../containers/Row";
import GenericText from "../texts/GenericText";
import { IInputProps } from "./IInput";
import InputColor from "./InputColor";
import InputCurrency from "./InputCurrency";
import InputPercentage from "./InputPercentage";
import InputTextArea from "./InputTextArea";
import { StyledInput } from "./styles";

const Input = (props: IInputProps) => {
  const { width, labelOrientation, ...inputProps } = props;

  return props.type === "color" ? (
    <InputColor {...props} />
  ) : props.maskType === "currency" ? (
    <InputCurrency {...props} />
  ) : props.maskType === "percentage" ? (
    <InputPercentage {...props} />
  ) : props.type === "textArea" ? (
    <InputTextArea {...inputProps} />
  ) : labelOrientation === "row" ? (
    <Row width={width || "auto"} margin={props.margin || "0px"}>
      {props.label && (
        <Row justifyContent="flex-start" margin="0px 0px 4px 0px">
          <GenericText size="h6">{`${props.label}${
            props.required ? "*" : ""
          }`}</GenericText>
        </Row>
      )}
      <StyledInput
        mask={getMaskType(props.maskType)}
        maskChar={""}
        placeholder={props.placeholder}
        {...inputProps}
      />
    </Row>
  ) : (
    <Column width={width || "auto"} margin={props.margin || "0px"}>
      {props.label && (
        <Row justifyContent="flex-start" margin="0px 0px 4px 0px">
          <GenericText size="h6">{`${props.label}${
            props.required ? "*" : ""
          }`}</GenericText>
        </Row>
      )}
      <StyledInput
        mask={getMaskType(props.maskType)}
        maskChar={""}
        placeholder={props.placeholder}
        {...inputProps}
      />
    </Column>
  );
};

export default Input;
