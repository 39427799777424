/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { ICustomerProduct } from "../../../interfaces/customer/ICustomer";

const FSearch = async (
  props: { company?: number | string; app?: boolean } = {}
): Promise<ICustomerProduct[]> => {
  const URL = queryFormatter({
    route: `dashboard/products`,
    querys: props,
  });
  try {
    const { data }: AxiosResponse<ICustomerProduct[]> = await axiosProvider.get(
      URL
    );
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default FSearch;
