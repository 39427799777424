import { useState } from "react";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";
import Row from "../../../../../../components/containers/Row";
import { useQuery } from "@tanstack/react-query";
import ProductsService from "../../../../../../services/api/products/ProductsService";
import { YesOrNot } from "../../../../../../mocks/YesOrNot";
const mockType = [
  {
    label: "Link externo",
    value: "href",
  },
  {
    label: "Módulo App",
    value: "module",
  },
];
export default function SecondStepPush(props: {
  push: Record<string, any>;
  changePush: (e: any) => void;
  changeImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const [showExtraInputs, setShowExtraInputs] = useState(
    props?.push?.checkButton
  );
  const { data } = useQuery({
    queryKey: ["products-search"],
    queryFn: () => ProductsService.search({ app: true }),
  });
  function changeCopy(
    v: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    props.changePush((el: any) => ({
      ...el,
      body: {
        ...el.body,
        components: {
          ...el?.body?.components,
          copy: { code: v.target.value },
        },
      },
    }));
  }
  function changeBody(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    props.changePush((el: any) => ({
      ...el,
      body: { ...el?.body, [e.target.name]: e.target.value },
    }));
  }
  function changeComponent(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    props.changePush((el: any) => ({
      ...el,
      body: {
        ...el?.body,
        components: {
          ...el?.body?.components,
          [e.target.name]: e.target.value,
        },
      },
    }));
  }
  return (
    <>
      <Input
        type="file"
        label="Imagem"
        margin={"0px 0px 5px 0px"}
        onChange={props.changeImage}
        required
      />
      <Input
        name="description"
        label="Descrição da página"
        value={props.push?.body?.description}
        onChange={changeBody}
        placeholder="Digite o descrição para a notificação"
        margin={"0px 0px 5px 0px"}
        required
      />

      <Input
        name="copyCode"
        label="Código promocional?"
        value={props.push?.body?.components?.copy?.code}
        onChange={changeCopy}
        placeholder="Digite o código promocional"
        margin={"0px 0px 5px 0px"}
      />
      <Input
        name="pageTitle"
        label="Titulo da tela"
        value={props.push?.body?.components?.pageTitle}
        onChange={changeComponent}
        placeholder="Digite o titulo da página"
        margin={"0px 0px 5px 0px"}
        required
      />
      <Input
        name="title"
        label="Titulo da página"
        value={props.push?.body?.title}
        onChange={changeBody}
        placeholder="Digite o titulo da página"
        margin={"0px 0px 5px 0px"}
        required
      />
      <Input
        name="rules"
        label="Regras da notificação"
        value={props.push?.body?.components?.rules}
        onChange={changeComponent}
        placeholder="Digite a regra da notificação"
        type="textArea"
        margin={"0px 0px 5px 0px"}
      />
      <Row>
        <Select
          value={
            props.push?.checkButton
              ? {
                  label: "Sim",
                  value: true,
                }
              : props.push?.checkButton === false
              ? {
                  label: "Não",
                  value: false,
                }
              : undefined
          }
          label="Botão de ação?"
          placeholder="Notificação com botão ?"
          data={YesOrNot}
          getOptionLabel={(e: any) => e.label}
          getOptionValue={(e: any) => e.value}
          onChange={(e: any) => {
            if (e.value === false) {
              props.changePush((el: any) => ({
                ...el,
                body: {
                  ...el.body,
                  components: { ...el.body?.components, button: null },
                },
                checkButton: e.value,
              }));
            }
            props.changePush((el: any) => ({
              ...el,
              checkButton: e.value,
            }));
            setShowExtraInputs(e.value);
          }}
        />

        {showExtraInputs && (
          <Select
            placeholder="Tipo da ação do botão"
            name="select-button"
            label="Tipo da ação"
            value={mockType.find(
              (el) => el.value === props.push?.body?.components?.button?.type
            )}
            data={mockType}
            getOptionLabel={(e: any) => e.label}
            getOptionValue={(e: any) => e.value}
            onChange={(e: any) =>
              props.changePush((el: any) => ({
                ...props.push,
                body: {
                  ...el?.body,
                  components: {
                    ...el?.body?.components,
                    button: { ...el?.body?.components?.button, type: e.value },
                  },
                },
              }))
            }
            required
          />
        )}
      </Row>
      {props.push?.body?.components?.button?.type && showExtraInputs && (
        <Input
          name="title"
          label="Texto do botão"
          value={props.push?.body?.components?.button?.text}
          onChange={({ target }) =>
            props.changePush((el: any) => ({
              ...el,
              body: {
                ...el?.body,
                components: {
                  ...el.body?.components,
                  button: { ...el.body.components.button, text: target.value },
                },
              },
            }))
          }
          placeholder="Digite o texto que vai dentro do botão"
          margin={"0px 0px 5px 0px"}
          required
        />
      )}
      {props.push?.body?.components?.button?.type === "module" &&
        showExtraInputs && (
          <Select
            placeholder="Escolha para qual módulo levar"
            name="rules"
            label="Módulo"
            value={
              data &&
              data.find(
                (e) => e.id === props.push?.body?.components?.button?.target
              )
            }
            data={data || []}
            getOptionLabel={(e: any) => e.appName}
            getOptionValue={(e: any) => e.id}
            onChange={(e: any) =>
              props.changePush((el: any) => ({
                ...el,
                body: {
                  ...el?.body,
                  components: {
                    ...el?.body?.components,
                    button: { ...el?.body?.components?.button, target: e.id },
                  },
                },
              }))
            }
            required
          />
        )}
      {props.push?.body?.components?.button?.type === "href" &&
        showExtraInputs && (
          <Input
            name="text"
            label="Link do botão"
            value={props.push?.body?.components?.button?.target}
            onChange={({ target }) =>
              props.changePush((el: any) => ({
                ...el,
                body: {
                  ...el?.body,
                  components: {
                    ...el?.body?.components,
                    button: {
                      ...el?.body?.components?.button,
                      target: target.value,
                    },
                  },
                },
              }))
            }
            placeholder="https://stations.cloud"
            margin={"0px 0px 5px 0px"}
            required
          />
        )}
    </>
  );
}
