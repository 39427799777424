import styled from "styled-components";
import Box from "../containers/Box";
import Column from "../containers/Column";

export const Container = styled(Column)`
  width: ${(props) => props.width};
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
`;

export const ContainerTextArea = styled(Column)`
  justify-content:space-evenly;
  width: 100%;
  margin: 20px 0px 40px 0px
`;

export const BoxTextArea = styled(Box)`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
`;
