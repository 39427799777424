/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { ISummaryPlans } from "./interface/ISummaryPlans";

const GetPlansSummary = async (companyId?: number): Promise<ISummaryPlans> => {
  const URL = queryFormatter({
    route: "plan/dashboard/admin/plan/count",
    querys: { companyId },
  });

  try {
    const { data }: AxiosResponse<ISummaryPlans> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetPlansSummary;
