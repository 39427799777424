import GenericText from "../texts/GenericText";
import { IChip } from "./IChip";
import { ChipContainer } from "./styles";

const Chip: React.FC<IChip> = ({ label, onDelete, color = "white" }) => {
  return (
    <ChipContainer textColor={color}>
      <GenericText color="white">{label}</GenericText>
      {onDelete && (
        <span className="delete-icon" onClick={onDelete}>
          &#x2716;
        </span>
      )}
    </ChipContainer>
  );
};

export default Chip;
