/* eslint-disable import/no-anonymous-default-export */
import FGeolocInformationByCep from "./FGeolocInformationByCep";
import GetCities from "./GetCities";
import GetCnpj from "./GetCnpj";
import GetStates from "./GetStates";

class Service {
  getStates = GetStates;
  getCities = GetCities;
  getCnpj = GetCnpj;
  geolocInformationByCep = FGeolocInformationByCep;
}

export default new Service();
