import moment from "moment";
import RowId from "../../../../components/Table/RowId";
import RowStatus from "../../../../components/Table/RowStatus";
import RowText from "../../../../components/Table/RowText";
import { IRow } from "../../../../components/Table/interface/IRow";

export const plansHome = (defaultProps: IRow = {}) => {
  return [
    {
      name: "ID",
      cell: (row: any) => <RowId {...defaultProps}>{row.id}</RowId>,
    },

    {
      name: "Nome",
      cell: (row: any) => <RowText {...defaultProps}>{row.title}</RowText>,
      grow: 1.8,
    },
    {
      name: "Descrição",
      cell: (row: any) => (
        <RowText {...defaultProps}>{row.description}</RowText>
      ),
    },
    {
      name: "Valor do plano",
      cell: (row: any) => (
        <RowText {...defaultProps}>
          {row.value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </RowText>
      ),
    },
    {
      name: "Frequência",
      cell: (row: any) => (
        <RowText {...defaultProps}>
          {row.paymentFrequency === "month" ? "Mensal" : "Anual"}
        </RowText>
      ),
    },
    {
      name: "ID empresa",
      cell: (row: any) => <RowText {...defaultProps}>{row.companyId}</RowText>,
    },
    {
      name: "Data limite",
      cell: (row: any) => (
        <RowText {...defaultProps}>
          {moment(row.limitDate).format("DD/MM/YYYY")}
        </RowText>
      ),
    },

    {
      name: " ",
      cell: (row: any) => <RowStatus status={row.status ? 1 : 0} />,
    },
  ];
};
