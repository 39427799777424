export default function checkFourthStep(object: Record<string, any>) {
  if (
    Array.isArray(object?.users) &&
    !object?.users?.length &&
    !object?.checkFourthStep
  ) {
    return true;
  }
  if (
    object?.checkFourthStep &&
    typeof JSON.parse(object?.checkFourthStep) !== "boolean"
  ) {
    return true;
  }

  return false;
}
