import styled from "styled-components";
import Row from "../../components/containers/Row";

export const LoginForm = styled.form`
  width: fit-content;
`;

export const LogoBox = styled(Row)`
  margin-bottom: 40px;
  justify-content: center;
`;

export const WelcomeTag = styled(Row)`
  background: ${(props) => props.theme.primary};
  width: 60%;
  border: 3.06101px solid ${(props) => props.theme.primary};
  border-radius: 10px;
  padding: 10px;
  font-style: normal;
  align-self: center;
  color: #ffffff;
  margin-bottom: 20px;
  justify-content: center;
 
`;
