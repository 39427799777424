import Button from "../buttons/Button";
import Row from "../containers/Row";
import Select from "../Select";
import GenericText from "../texts/GenericText";
import { Container, ContainerTextArea } from "./styles";
import { IButtonProps } from "../buttons/Button/interfaces/IButtonProps";
import ISelect from "../Select/ISelect";
import { IGenericTextProps } from "../texts/GenericText/interfaces/IGenericTextProps";
import Input from "../Input";
import { IInputProps } from "../Input/IInput";

interface IEditStatus {
  title: string;
  subTitle: string;
  width?: string | number;
  confirmButtonProps?: IButtonProps;
  selectProps?: ISelect;
  titleProps?: IGenericTextProps;
  subTitleProps?: IGenericTextProps;
  textAreaProps?: IInputProps;
}

const EditStatus = ({
  width,
  subTitle,
  title,
  titleProps = {},
  subTitleProps = {},
  confirmButtonProps = {},
  selectProps = { data: [] },
  textAreaProps = {},
}: IEditStatus) => {
  return (
    <Container width={width}>
      <GenericText size="h3" margin="0px 0px 12px" {...titleProps}>
        {title}
      </GenericText>
      <Select menuPosition="absolute" {...selectProps} />
      <ContainerTextArea>
        <GenericText
          size="h3"
          margin="0px 0px 5px"
          alignSelf="flex-start"
          {...subTitleProps}
        >
          {subTitle}
        </GenericText>
        <Input type="textArea" rows={6} {...textAreaProps} />
      </ContainerTextArea>
      <Row justifyContent="space-around">
        <Button
          text="Confirmar"
          width="90%"
          height="40px"
          {...confirmButtonProps}
        />
      </Row>
    </Container>
  );
};

export default EditStatus;
