import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { ICustomer } from "../../../interfaces/customer/ICustomer";

const FDetails = async ({ id }: { id?: number | string }): Promise<ICustomer> => {
  const URL = queryFormatter({
    route: "dashboard/company/" + id,
  });

  try {
    const { data }: AxiosResponse<ICustomer> = await axiosProvider.get(URL);
    return data;
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default FDetails;
