import { useEffect, useState } from "react";
import CheckBoxImage from "../../../../components/chekbox/CheckBoxImage";
import { Seprator } from "../../../../components/Exotics/Seprator";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";
import { IAssociate } from "../../../../interfaces/associates/IAssociate";
import useVerifyObjectEqual from "../../../../hooks/useVerifyObjectEqual";
import { useMutation } from "@tanstack/react-query";
import AssociatesService from "../../../../services/api/associates/AssociatesService";
import { toast } from "react-toastify";

const ProductsBox = ({ basic }: { basic: IAssociate }) => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["update-associate-products"],
    mutationFn: AssociatesService.updateAssociate,
  });

  const [details, setDetails] = useState<{
    products: number[];
  }>({ products: [] });
  const [initialObject, setInitialObject] = useState({});
  const { products } = basic;

  const disableButton = useVerifyObjectEqual({
    initialObject,
    actualObject: details,
  });

  const handleCheckboxChange = (id: number) => {
    setDetails((prevState) => ({
      products: prevState.products.includes(id)
        ? prevState.products.filter((productId) => productId !== id)
        : [...prevState.products, id],
    }));
  };

  useEffect(() => {
    if (products) {
      const initialSelectedIds = products
        .filter((product) => product.active)
        .map((product) => product.id);
      setDetails({ products: initialSelectedIds });
      setInitialObject({ products: initialSelectedIds });
    }
  }, [products]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Associado editado com sucesso");
    }
  }, [isSuccess]);

  const update = () => {
    mutate({ id: basic.id, body: details });
    setInitialObject(details);
  };

  return (
    <SubmitEditsForm
      title="Produtos"
      buttonProps={{
        disabled: disableButton,
        loading: isPending,
        onClick: update,
      }}
      renderInsideComponents={() => (
        <>
          <Seprator />
          {products.map((product, i) => (
            <CheckBoxImage
              key={`list-checkBoxImage-products-associate-${product.id}-${i}`}
              image={product.icon}
              alt={product.appName}
              checked={details.products.includes(product.id)}
              onChange={() => handleCheckboxChange(product.id)}
            />
          ))}
        </>
      )}
    />
  );
};

export default ProductsBox;
