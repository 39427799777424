import styled from "styled-components";
import Row from "../../../containers/Row";

export const LGContainer = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.65);
  z-index: 9999999999;
  overflow: hidden;
  position: fixed;
  inset: 0;
`;
