import styled from "styled-components";
import Box from "../../containers/Box";
import { IInputProps } from "../IInput";

export const BoxTextArea = styled(Box)`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea<IInputProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  border: none;
  resize: vertical;
  font-family: "Noto Sans", sans-serif;
  &:focus {
    outline: none;
  }
`;
