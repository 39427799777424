/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import moment from "moment";
import useVerifyObjectEqual from "../../../../hooks/useVerifyObjectEqual";
import { IUser } from "../../../../interfaces/users/IUser";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";
import Input from "../../../../components/Input";
import changeStateByInputName from "../../../../helpers/state/changeStateByInputName";

export default function UserInformationBox({ basic }: { basic: IUser }) {
  const [details, setDetails] = useState<Record<string, any>>({});
  const [initialObject, setInitialObject] = useState({});

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialObject,
    actualObject: details,
  });

  function init() {
    const object = {
      name: basic?.name,
      email: basic?.email,
      created: basic?.createdAt,
      updated: basic?.updatedAt,
    };
    setDetails(object);
    setInitialObject(object);
  }

  async function update() {
    setInitialObject(details);
  }

  useEffect(() => {
    basic && init();
  }, [basic]);

  return (
    <SubmitEditsForm
      title="Informações"
      buttonProps={{
        onClick: update,
        disabled: disabledButton,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="name"
            value={details?.name}
            onChange={(e) => changeStateByInputName(e, setDetails)}
            width={"100%"}
            label="Nome"
            type="text"
            margin={"5px 0px"}
            disabled
          />

          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="email"
            value={details?.email}
            width={"100%"}
            label="Email"
            type="text"
            margin={"5px 0px"}
            disabled
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="created"
            value={
              details?.created
                ? moment(details?.created).format("DD/MM/YYYY")
                : ""
            }
            width={"100%"}
            label="Criado em"
            type="text"
            disabled
            margin={"5px 0px"}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="updated"
            value={
              details?.updated
                ? moment(details?.updated).format("DD/MM/YYYY")
                : ""
            }
            width={"100%"}
            label="Atualizado em"
            type="text"
            disabled
            margin={"5px 0px"}
          />
        </>
      )}
    />
  );
}
