import Button from "../../buttons/Button";
import ContentSpacingContainer from "../../containers/ContentSpacingContainer";
import Row from "../../containers/Row";
import GenericText from "../../texts/GenericText";
import { IRegistrationFormProps } from "./interfaces";
import { CSCContainer, RegistrationContainer } from "./styles"; 

export default function RegistrationForm(props: IRegistrationFormProps) {
  const { buttonProps = {}, renderHeaderComponent = () => <></> } = props;
  return (
    <RegistrationContainer>
      <GenericText size={"h3"} margin={"0px 0px 20px 0px"}>{props.title}</GenericText>
      <Row margin={"0px 0px 0px 36px"} justifyContent="flex-start">
       {renderHeaderComponent()}
      </Row>
      <>
        {props.renderInsideComponents()}
      </>
      <Button
        text={"Cadastrar"}
        width={"100px"}
        height={"35px"}
        alignSelf="flex-end"
        margin={"25px 0px 0px 0px"}
        {...buttonProps}
      />
    </RegistrationContainer>
  );
}
