import { useLocation } from "react-router-dom";
import { IChildrenItem } from "../../interface/IChildrenItem";
import { IItemBar } from "../../interface/IItemBar";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import {
  PlainNavLink,
  SidebarContent,
  SidebarItemWrapper,
  SidebarTitle,
} from "./styles";
import GenericText from "../../texts/GenericText";
import Arrow from "../../../assets/svg/Arrow";

interface SidebarItemProps {
  item: IItemBar | IChildrenItem;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ item }) => {
  const hasChildren = (item as IItemBar).childrens !== undefined;
  const { pathname } = useLocation();
  const [open, setOpen] = useState(pathname.includes(item.slug));
  const theme = useTheme();

  useEffect(() => {
    if (item.slug === "/" && pathname !== "/") {
      setOpen(false);
      return;
    }
    setOpen(pathname.includes(item.slug));
  }, [pathname, item?.slug]);

  if (hasChildren) {
    return (
      <SidebarItemWrapper isMyItem={!open}>
        <SidebarTitle
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span>
            <GenericText color={theme.primary} size="h5" font="Regular">
              {item.title}
            </GenericText>
          </span>
          <Arrow style={open ? { transform: `rotate(${90}deg)` } : {}} />
        </SidebarTitle>
        <SidebarContent isOpen={open}>
          {(item as IItemBar).childrens?.map((child, index) => (
            <SidebarItem key={index} item={child} />
          ))}
        </SidebarContent>
      </SidebarItemWrapper>
    );
  } else {
    return (
      <PlainNavLink to={item.slug} active={open}>
        <GenericText color="#a1a1a1" font="Regular" size={"h6"}>
          {item.title}
        </GenericText>
      </PlainNavLink>
    );
  }
};

export default SidebarItem;
