/* eslint-disable no-throw-literal */
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";
import queryFormatter from "../../utils/queryFormatter";

const CreateVoucher = async ({
  type,
  batchId,
  date,
  establishmentId,
  productId,
  body,
  file,
}: {
  type: "file" | "body";
  establishmentId: number;
  productId: number;
  date: string;
  batchId: string;
  file?: any;
  body?: {
    vouchers: string[];
  };
}): Promise<void> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/voucher/create",
    querys: { batchId, date, establishmentId, productId },
  });

  try {
    if (type === "body") {
      await axiosProvider.post(URL, body);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    await axiosProvider.post(URL, formData);
  } catch (err: any) {
    console.log({ URL, error: err });
    throw err.response.data as DefaultErrorResponse;
  }
};

export default CreateVoucher;
