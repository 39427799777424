/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import { IAssociate } from "../../../interfaces/associates/IAssociate";
import queryFormatter from "../../utils/queryFormatter";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { axiosProvider } from "../../utils/axiosProvider";

const DetailsAssociate = async (id: string): Promise<IAssociate> => {
  const URL = queryFormatter({
    route: `dashboard/associates/${id}`,
  });
  try {
    const { data }: AxiosResponse<IAssociate> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default DetailsAssociate;
