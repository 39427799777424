import styled from "styled-components";
import Row from "../../containers/Row";
import Column from "../../containers/Column";

export const GrayContainer = styled(Column)`
  background-color: #f2f2f2;
  justify-content: space-between;
  border-radius: 8px;
  padding: 10px 0px 20px 0px;
`;
