/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import CinemaService from "../../../../../services/api/cinema/CinemaService";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import changeStateByInputName from "../../../../../helpers/state/changeStateByInputName";
import { formatDate } from "../../../../../helpers/date/formatDate";
import { removeMaskDate } from "../../../../../helpers/date/removeMaskDate";
import { toast } from "react-toastify";
import { IBrandDetails } from "../../../../../services/api/cinema/interface/IBrandDetails";
import GenericText from "../../../../../components/texts/GenericText";
import { useTheme } from "styled-components";

const VoucherInformationExcel = ({ basic }: { basic: IBrandDetails }) => {
  const theme = useTheme();

  const [details, setDetails] = useState<Record<string, any>>({});

  const { data: products } = useQuery({
    queryKey: ["get-products-cinema", basic.id],
    queryFn: () => CinemaService.getProductsCinema(basic.id),
  });

  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    mutationKey: ["create-voucher-cinema"],
    mutationFn: CinemaService.createVoucher,
  });

  const { mutate: getExampleFile, data: exampleFile } = useMutation({
    mutationKey: ["get-voucher-example"],
    mutationFn: CinemaService.getVoucherExample,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setDetails({ ...details, file: files[0] });
    }
  };
  const init = () => {
    const object = {
      establishmentId: basic.id,
      productId: null,
      date: "",
      batchId: "",
      file: null,
    };
    setDetails(object);
  };

  const update = () => {
    const { productId, batchId, date, file, establishmentId } = details;

    mutate({
      batchId,
      date: removeMaskDate(date),
      establishmentId,
      productId,
      type: "file",
      file,
    });
  };

  const disabledButton = useMemo(() => {
    const { productId, batchId, date, file } = details;
    return !(productId && batchId && date && file);
  }, [details]);

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    if (isSuccess) {
      const object = {
        establishmentId: basic.id,
        productId: null,
        date: "",
        batchId: "",
        file: null,
      };
      toast.success("Vouchers adicionados com sucesso");
      setDetails(object);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      const dataError = error as any;
      if (dataError?.link) {
        toast.error("Alguns vouchers apresentam erro");
        const aElement = document.createElement("a");
        aElement.href = dataError?.link;
        aElement.download = "vouchers-com-falha";
        aElement.style.display = "none";
        document.body.appendChild(aElement);
        aElement.click();
        document.body.removeChild(aElement);
      }
      return;
    }
  }, [error, isError]);

  useEffect(() => {
    if (exampleFile) {
      const blob = new Blob([exampleFile]);
      const url = URL.createObjectURL(blob);
      const aElement = document.createElement("a");
      aElement.href = url;
      aElement.download = "exemplo_vouchers.xlsx";
      aElement.style.display = "none";
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
      URL.revokeObjectURL(url);
    }
  }, [exampleFile]);

  return (
    <SubmitEditsForm
      title="Cadastrar voucher excel"
      buttonProps={{
        disabled: disabledButton,
        loading: isPending,
        onClick: update,
      }}
      renderInsideComponents={() => (
        <>
          <Select
            required
            value={
              products?.data?.filter(
                (product) => product.productId === details?.productId
              ) || 0
            }
            label="Produto"
            data={products?.data || []}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.productId}
            placeholder="Selecione o tipo de voucher"
            onChange={(e: any) => {
              setDetails((prev) => ({ ...prev, productId: e.productId }));
            }}
          />
          <Input
            required
            value={details.batchId}
            name="batchId"
            width={"100%"}
            label="Lote"
            type="text"
            margin={"5px 0px"}
            placeholder="Digite o lote "
            onChange={(e) => changeStateByInputName(e, setDetails)}
          />
          <Input
            required
            name="date"
            value={formatDate(details.date)}
            width="100%"
            label="Data de expiração código"
            placeholder="Digite a data de expiração"
            type="text"
            margin={"5px 0px"}
            onChange={(e) => changeStateByInputName(e, setDetails)}
          />
          <Input
            required
            label="Selecione seu arquivo "
            width={"100%"}
            type="file"
            margin={"9px 0px"}
            accept=".xlsx"
            onChange={handleFileChange}
          />
          <GenericText
            color={theme.primary}
            margin="10px 10px  0px"
            underline
            alignSelf="flex-start"
            cursor="pointer"
            size="h7"
            children={
              <p onClick={() => getExampleFile()}>Arquivo de exemplo</p>
            }
          />
        </>
      )}
    />
  );
};

export default VoucherInformationExcel;
