/* eslint-disable no-throw-literal */
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const PutVoucher = async ({
  voucherCode,
  motive,
}: {
  voucherCode: string;
  motive: string;
}): Promise<void> => {
  const URL = queryFormatter({
    route: "cinema/dashboard/voucher/withdraw/single",
    querys: { voucherCode, motive },
  });

  try {
    await axiosProvider.put(URL);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default PutVoucher;
