import { useState } from "react";
import LogoIcon from "../../assets/svg/LogoIcon";
import { LoginForm, LogoBox, WelcomeTag } from "./styles";
import Box from "../../components/containers/Box";
import useAuth from "../../hooks/useAuth";
import GenericText from "../../components/texts/GenericText";
import Button from "../../components/buttons/Button";
import Column from "../../components/containers/Column";
import Input from "../../components/Input";
import Row from "../../components/containers/Row";
const Login = () => {
  const { login: loginFeat } = useAuth();
  const [userName, setUsername] = useState("");
  const [passWord, setPassword] = useState("");

  return (
    <LoginForm
      onSubmit={(e) => {
        e.preventDefault();
        loginFeat.login({ login: userName, password: passWord });
      }}
    >
      <LogoBox>
        <LogoIcon width={300} height={100} />
      </LogoBox>
      <Box
        shadow
        alignItems="center"
        justifyContent="center"
        direction="column"
        width={"500px"}
      >
        <WelcomeTag>
          <GenericText font="Bold" size="h4" color="white">
            Seja bem-vindo(a) !
          </GenericText>
        </WelcomeTag>
        <Column width={"100%"}>
          <Input
            type="text"
            value={userName}
            onChange={(e) => setUsername(e.currentTarget.value)}
            placeholder="Email"
            width={"70%"}
            height={"45px"}
          />
          <Input
            type="password"
            value={passWord}
            onChange={(e) => setPassword(e.currentTarget.value)}
            placeholder="Senha"
            margin={"10px 0px"}
            width={"70%"}
            height={"45px"}
          />
        </Column>
        <Row width={"70%"} justifyContent="flex-end">
          <GenericText cursor="pointer" underline font="Light" size={"h5"}>
            Esqueci minha senha
          </GenericText>
        </Row>
        <Button
          loading={loginFeat.loading}
          width={"70%"}
          text={"Entrar"}
          margin={"20px 0px"}
          disabled={!userName || !passWord}
        />
      </Box>
    </LoginForm>
  );
};

export default Login;
