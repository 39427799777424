/* eslint-disable no-throw-literal */
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const UpdateStatus = async ({
  id,
  type,
  body,
}: {
  id?: string;
  type: "active" | "inative";
  body: {
    companyId: number;
    cpf: string;
    comments: string;
  };
}): Promise<void> => {
  const URL = queryFormatter({
    route: `dashboard/associates/${id}`,
  });

  try {
    if (type === "active") {
      await axiosProvider.put(URL, { active: true });
    } else {
      await axiosProvider.delete(URL, { data: body });
    }
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default UpdateStatus;
