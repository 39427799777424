import { IItemBar } from "../interface/IItemBar";

export interface IModuleNavBar {
  modules: IItemBar[] | [];
}

const isDev = process.env.REACT_APP_DEV === "true";
const baseUrl = !isDev
  ? "https://starbase.stations.cloud"
  : process.env.REACT_APP_URL;

export const mock: IModuleNavBar = {
  modules: [
    {
      title: "Início",
      slug: "/",
      childrens: [
        {
          title: "Home",
          icon: "",
          slug: `${baseUrl}/home`,
        },
      ],
    },
    {
      title: "Clientes",
      slug: "/cliente",
      childrens: [
        {
          title: "Listar Clientes",
          icon: "",
          slug: `${baseUrl}/clientes/home`,
        },
        {
          title: "Cadastrar cliente",
          icon: "",
          slug: `${baseUrl}/clientes/cadastro`,
        },
      ],
    },
    {
      title: "Associados",
      slug: "/associados/",
      childrens: [
        {
          title: "Buscar Associados",
          icon: "",
          slug: `${baseUrl}/associados/home`,
        },
        {
          title: "Cadastrar Associados",
          icon: "",
          slug: `${baseUrl}/associados/cadastro`,
        },
      ],
    },
    {
      title: "Cinema",
      slug: "/cinema",
      childrens: [
        {
          title: "Buscar Cinemas",
          icon: "",
          slug: `${baseUrl}/cinema/home`,
        },
      ],
    },
    {
      title: "Marketing",
      slug: "/marketing",
      childrens: [
        {
          title: "Notificação Push",
          icon: "",
          slug: `${baseUrl}/marketing/notificacao/push?step=1`,
        },
        {
          title: "Planos",
          icon: "",
          slug: `${baseUrl}/marketing/planos`,
        },
      ],
    },
  ],
};
