const convertEmptyStringsToNullOrDelete = (obj: any, _delete?: boolean) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] &&
      typeof newObj[key] === "object" &&
      !Array.isArray(newObj[key])
    ) {
      newObj[key] = convertEmptyStringsToNullOrDelete(newObj[key], _delete);
    } else if (
      newObj[key] === "" ||
      newObj[key] === null ||
      newObj[key] === 0
    ) {
      if (_delete) {
        delete newObj[key];
      } else {
        newObj[key] = null;
      }
    }
  });
  return newObj;
};

export default convertEmptyStringsToNullOrDelete;
