import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import { ISummary } from "./interfaces/ISummary";

const FSummary = async (props: {
  id: string | undefined;
}): Promise<ISummary> => {
  const URL = queryFormatter({
    route: "dashboard/app/users/count?companyId=" + props.id,
  });

  try {
    const { data }: AxiosResponse<ISummary> = await axiosProvider.get(URL);
    return data;
  } catch (err: any) {
    throw err.response.data as DefaultErrorResponse;
  }
};

export default FSummary;
