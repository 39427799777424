import styled from "styled-components";
import Column from "../containers/Column";
import Box from "../containers/Box";

export const Container = styled(Column)`
  width: ${(props) => props.width};
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
`;

export const ContainerTextArea = styled(Column)`
  justify-content: space-evenly;
  width: 100%;
  margin: 20px 0px 40px 0px;
`;


