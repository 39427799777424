import styled from "styled-components";

export const ModalContent = styled.div`
  position: absolute;
  inset: 50% auto auto 50%;
  border-radius: 8px;
  overflow: auto;
  outline: none;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  opacity: 1;
`;

export const ModalOverlay = styled.div`
  inset: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.24);
  z-index: 999999;
`;
