import { ScaleLoader } from "react-spinners";
import GenericText from "../../texts/GenericText";
import { IButtonProps } from "./interfaces/IButtonProps";
import { BContainer } from "./styles";
import Row from "../../containers/Row";

export default function (props: IButtonProps) {
  const { textProps = {} } = props;
  return (
    <BContainer {...props}>
      {props.loading ? (
        <Row>
          <ScaleLoader color="white" height={20} />
        </Row>
      ) : (
        <GenericText
          font={"Regular"}
          size={"h5"}
          color={"white"}
          {...textProps}
        >
          {props.text}
        </GenericText>
      )}
    </BContainer>
  );
}
