/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import SubmitEditsForm from "../../../../components/forms/SubmitEditsForm";
import Input from "../../../../components/Input";
import { IAssociate } from "../../../../interfaces/associates/IAssociate";
import changeStateByInputName from "../../../../helpers/state/changeStateByInputName";
import useVerifyObjectEqual from "../../../../hooks/useVerifyObjectEqual";
import { useMutation } from "@tanstack/react-query";
import AssociatesService from "../../../../services/api/associates/AssociatesService";
import convertEmptyStringsToNullOrDelete from "../../../../helpers/object/convertEmptyStringsToNull ";
import { formatDate } from "../../../../helpers/date/formatDate";
import { removeMaskDate } from "../../../../helpers/date/removeMaskDate";
import { toast } from "react-toastify";
import { IUser } from "../../../../interfaces/users/IUser";
import moment from "moment";

const InformationBox = ({
  basic,
  user,
}: {
  basic: IAssociate;
  user: IUser;
}) => {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["update-associate-information"],
    mutationFn: AssociatesService.updateAssociate,
  });

  const [details, setDetails] = useState<Record<string, any>>({});
  const [actualObject, setActualObject] = useState({});
  const disabledButton = useVerifyObjectEqual({
    actualObject,
    initialObject: details,
  });

  const init = () => {
    const object = {
      name: basic?.name,
      cpf: basic?.cpf,
      birthDate: basic?.birthDate,
      email: basic?.email,
      phone: basic?.phone,
    };
    setActualObject(object);
    setDetails(object);
  };

  const update = () => {
    const update = {
      ...details,
      birthDate: removeMaskDate(details.birthDate),
    };
    const formatObject = convertEmptyStringsToNullOrDelete(update, true);
    mutate({ id: basic.id, body: formatObject });
    setActualObject(details);
  };

  useEffect(() => {
    basic && init();
  }, [basic]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Associado editado com sucesso");
    }
  }, [isSuccess]);

  return (
    <SubmitEditsForm
      title="Informações"
      buttonProps={{
        disabled: disabledButton,
        onClick: update,
        loading: isPending,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            name="name"
            value={details.name}
            width="100%"
            label="Nome"
            type="text"
            onChange={(e) => changeStateByInputName(e, setDetails)}
            placeholder="Nome associado"
            margin={"5px 0px"}
          />
          <Input
            name="cpf"
            value={details.cpf}
            width="100%"
            label="Cpf"
            disabled
            maskType="cpf"
            placeholder="Cpf"
            type="text"
            margin={"5px 0px"}
            onChange={(e) => changeStateByInputName(e, setDetails)}
          />

          <Input
            name="birthDate"
            value={formatDate(details.birthDate)}
            width="100%"
            label="Data de nascimento"
            type="text"
            placeholder="dd/mm/yyy"
            margin={"5px 0px"}
            onChange={(e) => changeStateByInputName(e, setDetails)}
          />

          <Input
            name="email"
            value={details.email}
            width="100%"
            label="E-mail"
            type="text"
            placeholder="stations@gmail.com"
            margin={"5px 0px"}
            onChange={(e) => changeStateByInputName(e, setDetails)}
          />
          <Input
            name="phone"
            value={details.phone}
            width="100%"
            label="Telefone"
            type="text"
            placeholder="(xx) xxxxx - xxxx"
            margin={"5px 0px"}
            onChange={(e) => changeStateByInputName(e, setDetails)}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="created"
            value={
              user?.createdAt
                ? moment(user?.createdAt).format("DD/MM/YYYY")
                : ""
            }
            width={"100%"}
            label="Usuário Criado"
            type="text"
            disabled
            margin={"5px 0px"}
          />
          <Input
            onChange={(e) => changeStateByInputName(e, setDetails)}
            name="updated"
            value={
              user?.updatedAt
                ? moment(user?.updatedAt).format("DD/MM/YYYY")
                : ""
            }
            width={"100%"}
            label="Usuário Atualizado"
            type="text"
            disabled
            margin={"5px 0px"}
          />
        </>
      )}
    />
  );
};

export default InformationBox;
