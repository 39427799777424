import {  ScaleLoader } from "react-spinners";
import { LGContainer } from "./styles";
import { useTheme } from "styled-components";

export default function () {
  const theme = useTheme();
  return (
    <LGContainer>
      <ScaleLoader width={"10px"} height={"60px"} color={theme.primary} />
    </LGContainer>
  );
}
