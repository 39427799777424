import styled from "styled-components";

export const EHImage = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1), 0 3px 6px 6px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-right: 80px;
  `
