import { useTheme } from "styled-components";

function LogoutIcon() {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 55 55"
    >
      <path
        stroke={theme.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.39"
        d="M46.33 27.12l-6.78-6.78m-18.08 6.78h24.86-24.86zm24.86 0l-6.78 6.78 6.78-6.78z"
      ></path>
      <path
        stroke={theme.primary}
        d="M11.3 18.08v18.08c0 2.23.01 2.95.098 3.498a7.91 7.91 0 006.575 6.575c.548.087 1.268.098 3.497.098 2.23 0 2.949-.011 3.498-.098a7.91 7.91 0 006.575-6.575c.087-.549.097-1.269.097-3.498v-.565a1.695 1.695 0 013.39 0v.565c0 2.1 0 3.15-.139 4.028a11.3 11.3 0 01-9.393 9.393c-.879.14-1.928.14-4.028.14-2.1 0-3.15 0-4.028-.14a11.3 11.3 0 01-9.393-9.393c-.139-.878-.139-1.928-.139-4.028V18.08c0-2.1 0-3.15.14-4.028a11.3 11.3 0 019.392-9.393c.879-.139 1.929-.139 4.028-.139 2.1 0 3.15 0 4.028.14a11.3 11.3 0 019.393 9.392c.14.879.14 1.929.14 4.028v.565a1.695 1.695 0 01-3.39 0v-.565c0-2.229-.011-2.949-.098-3.497a7.91 7.91 0 00-6.575-6.575c-.549-.087-1.269-.098-3.498-.098s-2.949.01-3.497.098a7.91 7.91 0 00-6.575 6.575c-.087.548-.098 1.268-.098 3.497z"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
