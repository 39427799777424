/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import Box from "../../components/containers/Box";
import Table from "../../components/Table";
import RowId from "../../components/Table/RowId";
import RowText from "../../components/Table/RowText";
import CheckBoxImage from "../../components/chekbox/CheckBoxImage";
import InputSearch from "../../components/Input/InputSearch";

const PlayGround = () => {
  const dataTable = [
    { id: 1, title: "Conan the Barbarian", year: "1982" },
    { id: 2, title: "The Terminator", year: "1984" },
    { id: 3, title: "Predator", year: "1987" },
    { id: 4, title: "Total Recall", year: "1990" },
    { id: 5, title: "Terminator 2: Judgment Day", year: "1991" },
    { id: 6, title: "Last Action Hero", year: "1993" },
    { id: 7, title: "True Lies", year: "1994" },
    { id: 8, title: "Eraser", year: "1996" },
    { id: 9, title: "Batman & Robin", year: "1997" },
    { id: 10, title: "End of Days", year: "1999" },
    { id: 11, title: "The 6th Day", year: "2000" },
    { id: 12, title: "Collateral Damage", year: "2002" },
    { id: 13, title: "Terminator 3: Rise of the Machines", year: "2003" },
    { id: 14, title: "Around the World in 80 Days", year: "2004" },
    { id: 15, title: "The Expendables", year: "2010" },
  ];

  const colums = [
    {
      name: "ID",
      cell: (row: any) => <RowId>{row.id}</RowId>,
    },
    {
      name: "Titulo",
      cell: (row: any) => <RowText>{row.title}</RowText>,
    },
    {
      name: "Ano",
      cell: (row: any) => <RowText>{row.year}</RowText>,
    },
  ];

  const [searchTable, setSearchTable] = useState("");

  const filterDataTable = useMemo(() => {
    if (searchTable.length) {
      return dataTable.filter((colum) =>
        colum.title.toLowerCase().includes(searchTable)
      );
    }

    return dataTable;
  }, [searchTable]);

  return (
    <div>
      <div style={{ marginTop: 40 }}>
        <CheckBoxImage
          width="70%"
          image="https://node.stations.cloud/images/products/saude.png"
        />
        <CheckBoxImage
          width="70%"
          image="https://node.stations.cloud/images/products/pay2.png"
        />
        <CheckBoxImage
          width="70%"
          image="https://node.stations.cloud/images/products/pratique3.png"
        />
      </div>
      <Box width="90%">
        <Box width="90%">
          <Table
            data={filterDataTable}
            columns={colums}
            pagination
            selectableRows
            headerComponents={[
              <InputSearch
                type="text"
                value={searchTable}
                label="Pesquisar por nome"
                onChange={(e) => setSearchTable(e.target.value)}
                placeholder="Digite o nome"
              />,
            ]}
          />
        </Box>
      </Box>
    </div>
  );
};

export default PlayGround;
