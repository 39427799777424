/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const GetVoucherExample = async (): Promise<any> => {
  const URL = queryFormatter({
    route: `cinema/dashboard/voucher/example`,
  });

  try {
    const { data }: AxiosResponse<any> = await axiosProvider.get(URL);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default GetVoucherExample;
