import styled from "styled-components";

interface IStatus {
  status: number;
}

export const Container = styled.div`
  width: 100px;
`;

export const Status = styled.div<IStatus>`
  background-color: ${(pros) =>
    pros.status === 0
      ? "#df0000"
      : pros.status === 1
      ? "#569A59"
      : "rgb(255, 153, 0)"};
  height: 30px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
