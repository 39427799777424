import React, { useEffect, useState } from "react";
import GenericText from "../texts/GenericText";
import Row from "../containers/Row";

interface SVGImageProps {
  src: string;
  alt: string;
  color?: string;
  width?: string;
  replaceColor?: string;
}

const SVG: React.FC<SVGImageProps> = ({
  src,
  alt,
  color,
  width = "auto",
  replaceColor,
}) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);

  useEffect(() => {
    const fetchSVG = async () => {
      try {
        const response = await fetch(src);
        let svgText = await response.text();

        // Modificar a cor do SVG
        if (color && replaceColor) {
          svgText = svgText.replace(
            new RegExp(`${String(replaceColor)}`, "g"),
            color
          );
        }

        // Adicionar estilos para tornar o SVG responsivo
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgElement = svgDoc.querySelector("svg");

        if (svgElement) {
          svgElement.setAttribute("width", "100%");
          svgElement.setAttribute("height", "100%");
          svgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");

          const serializedSVG = new XMLSerializer().serializeToString(
            svgElement
          );
          setSvgContent(serializedSVG);
        } else {
          setSvgContent(svgText);
        }
      } catch (error) {
        console.error("Error fetching the SVG:", error);
      }
    };

    fetchSVG();
  }, [src, color, replaceColor, width]);

  return (
    <Row width={width} >
      {svgContent ? (
        <Row
          style={{ width: "100%", height: "100%" }}
          dangerouslySetInnerHTML={{ __html: svgContent }}
        />
      ) : (
        <GenericText>{alt}</GenericText>
      )}
    </Row>
  );
};

export default SVG;
