export function removeMaskDate(dateString: string): string {
  const regexWithSep = /^\d{2}\/\d{2}\/\d{4}$/;
  if (regexWithSep.test(dateString)) {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  }

  const regexNoSep = /^\d{8}$/;
  if (regexNoSep.test(dateString)) {
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    return `${year}-${month}-${day}`;
  }

  return "";
}
