import styled from "styled-components";

export const Container = styled.div`
  width: 100px;
`;

export const IdTag = styled.div`
  background-color: ${(pros) => pros.theme.primary};
  height: 30px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
