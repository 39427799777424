import { IButtonIcon } from "../Button/interfaces/IButtonIcon";
import { ButtonContainer } from "./styles";

const ButtonIcon = (props: IButtonIcon) => {
  const { children } = props;
  return (
    <ButtonContainer text="" {...props}>
      {children}
    </ButtonContainer>
  );
};

export default ButtonIcon;
