import styled from "styled-components";
import Row from "../../containers/Row";
import Button from "../../buttons/Button";
import { IEntityHeaderProps } from "./interfaces/IEntityHeaderProps";

export const EHContainer = styled(Row)`
  align-items: center;

  padding: 10px 30px;
  width: 100%;
  justify-content: space-between;
`;
export const EHActionBox = styled(Row)<{ cursor: "pointer" | "default" }>`
  width: auto;
  cursor: ${(props) => props.cursor};
  &:hover {
    opacity: 0.8;
  }
  margin-right: 20px;
`;
export const EHImage = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1), 0 3px 6px 6px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

export const EHStatusButton = styled(Button)<{
  status: IEntityHeaderProps["status"];
}>`
  background-color: ${(props) =>
    props.status === "active"
      ? "rgb(7, 188, 12)"
      : props.status === "inactive"
      ? "red"
      : "yellow"};
  width: 120px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1), 0 3px 6px 6px rgba(0, 0, 0, 0.08);
  height: 40px;
`;
