import styled from "styled-components";
import { IGenericTextProps } from "./interfaces/IGenericTextProps";
import { FontWeight } from "./configs/weight";
import { FontSize } from "./configs/size";

export const GenericalString = styled.p<IGenericTextProps>`
  font-weight: ${(props) => (props.font ? FontWeight[props.font] : 500)};
  font-size: ${(props) =>
    typeof props.size === "number"
      ? props.size + "px"
      : props.size
      ? FontSize[props.size] + "px"
      : "14px"};
  margin: 0;
  letter-spacing: 0.5px;
  ${(props) => props.padding && `padding:${props.padding};`}
  ${(props) => props.margin && `margin:${props.margin};`}
  ${(props) => props.underline && `text-decoration: underline;`}
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) => (props.color ? `color: ${props.color}` : `color:#1b1b1b`)};
  ${(props) => props.alignSelf && `align-self: ${props.alignSelf};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  font-family: "Noto Sans", sans-serif;
`;
