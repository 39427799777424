/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Input from "../../../../../components/Input";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function MvnoTip({ basic }: { basic: ICustomer }) {
  const [token, setToken] = useState("");
  const [initialObject, setInitialObject] = useState<Record<any, any>>({});
  const { data, isSuccess: querySuccess } = useQuery({
    queryKey: ["get-integration-mvno", basic?.id],
    queryFn: () => CustomerService.getMvnoIntegration(basic?.id),
    enabled: !!basic.id,
    retry: false,
  });
  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["update-integration-mvno"],
    mutationFn: CustomerService.updateOrCreateTokenMvno,
  });
  useEffect(() => {
    if (querySuccess) {
      setInitialObject({ token: data.token });
      setToken(data.token);
    }
  }, [querySuccess]);

  const update = () => {
    mutate({
      id: basic.id,
      update: { token },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setInitialObject({ token });
      toast.success("Dados salvos com sucesso!");
    }
  }, [isSuccess]);
  return (
    <SubmitEditsForm
      title="MVNO TIP"
      buttonProps={{
        disabled:
          !token || token === data?.token || token === initialObject?.token,
        onClick: update,
        loading: isPending,
      }}
      renderInsideComponents={() => (
        <>
          <Input
            width={"100%"}
            label="Token"
            value={token}
            name="token"
            placeholder="Insira o token para habilitar a integração"
            onChange={(e) => setToken(e.target.value)}
            type="text"
            margin={"5px 0px"}
          />
        </>
      )}
    />
  );
}
